import {
  Badge,
  Body1Strong,
  CounterBadge,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroupHeader,
  MenuItem,
  MenuItemCheckbox,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { priorities } from "shared";
import { Filter20Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { useMainStore } from "controller";
export const FilterComponent = () => {
  const style = useStyle();
  const filters = useMainStore((state) => state.homefilter);
  const setFilters = useMainStore((state) => state.setHomeFilter);
  const [filterValues, setFilterValues] = useState<any>({
    priority: { priority: [] },
    date: { date: [] },
    sort: { sort: ["Date : Last to First"] },
  });

  useEffect(() => {
    setFilterValues({
      priority: { priority: filters.priority },
      date: { date: [filters.date] },
      sort: { sort: [getSortValue()] },
    });
  }, []);

  const getSortValue = () => {
    for (const option of sortOptions) {
      if (
        option.option.sortDirection === filters.sortDirection &&
        option.option.sortBy === filters.sortBy
      ) {
        return option.value;
      }
      return "Date : Last to First";
    }
  };

  const setSortValue = () => {
    for (const option of sortOptions) {
      if (option.value === filterValues.sort.sort[0]) {
        return option.option;
      }
    }
    return { sortDirection: "desc", sortBy: "tickets.created_at" };
  };

  const onChange: MenuProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    setFilterValues((s: any) => {
      return {
        ...s,
        [name]: s?.[name]?.[name]
          ? { ...s?.[name]?.[name], [name]: checkedItems }
          : { [name]: checkedItems },
      };
    });
  };

  const onDateChange: MenuProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    setFilterValues((s: any) => {
      return {
        ...s,
        [name]: s?.[name]?.[name]
          ? {
              ...s?.[name]?.[name],
              [name]:
                s?.[name]?.[name][0] === checkedItems[0] ? [] : checkedItems,
            }
          : { [name]: checkedItems },
      };
    });
  };

  return (
    <div className={style.badge_container}>
      <CounterBadge
        className={style.badge}
        count={
          filterValues.priority.priority.length + filterValues.date.date.length
        }
        color="brand"
      />
      <Menu
        checkedValues={filterValues}
        onOpenChange={(event, data) => {
          if (!data.open) {
            setFilters({
              date: filterValues.date.date[0],
              priority: filterValues.priority.priority,
              ...setSortValue(),
            });
          }
        }}
      >
        <MenuTrigger disableButtonEnhancement>
          <MenuButton appearance="secondary" icon={<Filter20Regular />}>
            Filter
          </MenuButton>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuGroupHeader>
              <Body1Strong
              // style={{
              //   color: "black",
              // }}
              >
                {filterValues.priority.priority.length +
                  filterValues.date.date.length}{" "}
                filters applied
              </Body1Strong>
            </MenuGroupHeader>
            <MenuDivider />
            {/* Priority */}
            <Menu
              checkedValues={filterValues.priority}
              onCheckedValueChange={onChange}
            >
              <MenuTrigger disableButtonEnhancement>
                <MenuItem>Priority</MenuItem>
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  {priorities.map((item) => (
                    <MenuItemCheckbox
                      key={item.value}
                      value={item.value}
                      autoCapitalize="true"
                      name={"priority"}
                    >
                      {item.label}
                    </MenuItemCheckbox>
                  ))}
                </MenuList>
              </MenuPopover>
            </Menu>

            {/* Date */}
            <Menu
              checkedValues={filterValues.date}
              persistOnItemClick
              onCheckedValueChange={onDateChange}
            >
              <MenuTrigger disableButtonEnhancement>
                <MenuItem>Date</MenuItem>
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  {filterDatesOptions.map((item) => (
                    <MenuItemRadio
                      key={item.value}
                      value={item.value}
                      autoCapitalize="true"
                      name={"date"}
                    >
                      {item.label}
                    </MenuItemRadio>
                  ))}
                </MenuList>
              </MenuPopover>
            </Menu>

            <MenuDivider />
            <Menu
              checkedValues={filterValues.sort}
              onCheckedValueChange={onChange}
              persistOnItemClick
            >
              <MenuTrigger disableButtonEnhancement>
                <MenuItem>Sort by</MenuItem>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  {sortOptions.map((item: any) => (
                    <MenuItemRadio
                      key={item.value}
                      name={"sort"}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItemRadio>
                  ))}
                </MenuList>
              </MenuPopover>
            </Menu>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

const useStyle = makeStyles({
  badge_container: {
    position: "relative",
  },
  badge: {
    position: "absolute",
    left: "-4px",
    top: "-4px",
  },
});

const filterDatesOptions = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 Days",
    value: "last 7 days",
  },
  {
    label: "Last 30 Days",
    value: "last 30 days",
  },
  {
    label: "Last 3 Months",
    value: "last 3 months",
  },
  {
    label: "Last 6 Months",
    value: "last 6 months",
  },
  {
    label: "Last 12 Months",
    value: "last 12 months",
  },
];

const sortOptions: any = [
  {
    label: "Date : Last to First",
    value: "Date : Last to First",
    option: {
      sortBy: "tickets.created_at",
      sortDirection: "desc",
    },
  },
  {
    label: "Date : First to Last",
    value: "Date : First to Last",
    option: {
      sortBy: "tickets.created_at",
      sortDirection: "asc",
    },
  },
  {
    label: "Summary : A to Z",
    value: "Summary : A to Z",
    option: {
      sortBy: "tickets.ticket_summary",
      sortDirection: "asc",
    },
  },
  {
    label: "Summary : Z to A",
    value: "Summary : Z to A",
    option: {
      sortBy: "tickets.ticket_summary",
      sortDirection: "desc",
    },
  },
];
