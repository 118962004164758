import { Button, ProgressBar, Text } from "@fluentui/react-components";
import { Stack } from "components/Stack";
import { CloseIcon } from "components/icons";
import { AttachmentFileData, FileIcon } from "shared";

export const AttachmentTile = ({
  file,
  status,
  percentage,
  onRemove,
}: {
  file: AttachmentFileData;
  status?: string;
  percentage: number;
  onRemove?: () => void;
}) => {
  return (
    <Stack
      key={file.key}
      direction="row"
      width="100%"
      // sx={{
      //   borderBottom: `1px solid ${theme.palette.neutral["020"]}`,
      //   borderBottomStyle: "dashed",
      // }}
      sx={{
        borderBottom: "1px solid #e3e3e3",
        borderBottomStyle: "dashed",
        paddingBottom: "16px",
        paddingTop: "16px",
        gap: "16px",
      }}
    >
      <FileIcon type={file.type} />
      <Stack
        direction="row"
        sx={{
          gap: "16px",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <Text as="span">{file.name}</Text>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Text as="span" color={"grey"}>
              {status}...
            </Text>
            <Text as="span" color={"grey"}>
              {percentage}%
            </Text>
          </Stack>
        </div>
      </Stack>

      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          gap: "32px",
          flex: "1",
        }}
      >
        {/* <LinearProgress
          variant="determinate"
          color="success"
          value={percentage}
          sx={{ width: "200px", height: "8px" }}
        /> */}
        <ProgressBar max={100} value={percentage} />
        <Button
          appearance="transparent"
          icon={<CloseIcon />}
          aria-label="Edit"
          onClick={onRemove}
        />
      </Stack>
    </Stack>
  );
};
