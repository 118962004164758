import { Body1 } from "@fluentui/react-components";
import { Box, Modal } from "components";

export const CancelTicketModal = ({
  isOpen,
  onClose,
  onSubmit,
  isMultiple=true
}: {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: () => void;
  isMultiple:boolean
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      title={`Cancel ${isMultiple?"tickets":"ticket"}`}
      primaryButtonLabel={`Cancel ${isMultiple?"tickets":"ticket"}`}
      secondaryButtonLabel="Discard"
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={() => onClose()}
    >
      <Body1>Are you sure you want to cancel selected ticket{isMultiple&&"s"}?</Body1>
    </Modal>
  );
};
