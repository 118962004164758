import {
  makeStyles,
  shorthands,
  mergeClasses,
  Body1,
  Caption1,
  Caption1Stronger,
  Avatar,
} from "@fluentui/react-components";
import { getFullName, isoToDateAndTime } from "Utils";
import { Attachment, AttachmentTile } from "components";
import { useGlobalStore } from "controller";
import { memo } from "react";
import ReactQuill from "react-quill";
import "./ChatBubble.css";
export const ChatBubble = ({ messageDetails }: { messageDetails: any }) => {
  const customerUserId = useGlobalStore((state) => state.userId);

  const isMine = messageDetails?.sender.mapping_id === customerUserId;
  const style = useStyle();
  return (
    <div
      className={mergeClasses(
        style.chatBubbleContainer,
        isMine ? style.sender : style.reciver
      )}
    >
      {!isMine && (
        <Avatar
          color="colorful"
          name={messageDetails?.sender?.display_name}
          idForColor={messageDetails?.sender?.mapping_id}
          id={messageDetails?.sender?.mapping_id}
          image={{
            src: messageDetails?.sender?.profile_pic,
          }}
        />
      )}

      <div
        className={mergeClasses(
          style.chatBubble,
          isMine ? style.sender_Box : style.reciver_Box
        )}
      >
        <div
          className={mergeClasses(
            style.box,
            isMine ? style.senderBubble : style.reciverBubble
          )}
        >
          <Caption1 className={style.date}>
            {!isMine && (
              <Caption1Stronger>
                {messageDetails?.sender?.display_name}
                {"  "}
              </Caption1Stronger>
            )}
            {isoToDateAndTime(messageDetails?.created_at, {
              format: "DD/MM/YY hh:mm a",
            })}
          </Caption1>
          {(Array.isArray(messageDetails?.attachments)
            ? messageDetails.attachments
            : []
          ).map((item: any) => (
            <Attachment
              title={item?.file_name}
              type={item?.file_type}
              fileUrl={item?.url}
            />
          ))}
          {messageDetails?.origin_type === "Email" ||
          ["canned_notes", "time_entry_notes"].includes(
            messageDetails?.message_type
          ) ? (
            <>
              <div className="quill_container">
                <ReactQuillComponent message={messageDetails?.message} />
              </div>
              {messageDetails?.origin_type === "Email" && (
                <Caption1 className={style.mail_badge}>Mail ticket</Caption1>
              )}
            </>
          ) : (
            <Body1 className={style.message}>{messageDetails?.message}</Body1>
          )}
        </div>
      </div>
    </div>
  );
};
const ReactQuillComponent = memo(function ReactQuillComponent({
  message,
}: {
  message: string;
}) {
  return (
    <ReactQuill
      readOnly
      style={{
        height: "100%",
        border: "none",
      }}
      className=""
      theme="snow"
      value={message}
      onChange={() => {}}
      modules={{
        toolbar: false,
      }}
    />
  );
});

const useStyle = makeStyles({
  chatBubbleContainer: {
    width: "100%",
    display: "flex",
    marginBottom: "16px",
    flexDirection: "row",
    ...shorthands.overflow("clip"),
    ...shorthands.gap("8px"),
  },
  chatBubble: {
    maxWidth: "min(450px , 70%)",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("4px"),
  },
  box: {
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("4px"),
    ...shorthands.padding("12px", "16px"),
    ...shorthands.borderRadius("4px"),
    textOverflow: "clip",
    overflowX: "hidden",
  },

  sender: {
    justifyContent: "end",
  },
  reciver: {
    justifyContent: "start",
  },
  sender_Box: {
    alignItems: "end",
  },
  reciver_Box: {
    alignItems: "start",
  },
  senderBubble: {
    backgroundColor: "#E8EBFA",
  },
  reciverBubble: {
    backgroundColor: "#F5F5F5",
  },
  message: {
    color: "#242424",
  },
  date: {
    color: "#424242",
  },
  mail_badge: {
    backgroundColor: "#F5F5F5",
    width: "fit-content",
    color: "#001199",
    ...shorthands.padding("4px", "8px"),
    ...shorthands.borderRadius("4px"),
  },
});
