import {
  AvatarGroup,
  AvatarGroupItem,
  AvatarGroupPopover,
  Body1Strong,
  Body1Stronger,
  Button,
  Caption1,
  makeStyles,
  partitionAvatarGroupItems,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { getFullName, isoToDateAndTime } from "Utils";
import { Attachment, PriorityBadge, StatusBadge } from "components";
import { useState } from "react";
import ReactQuill from "react-quill";
import { TicketDescriptionModal } from "./TicketDescriptionModal";

export const LeftSideBar = ({ ticketDetails }: { ticketDetails: any }) => {
  const style = useStyle();
  const [isExpanded, setIsExpanded] = useState(false);
  const partitionedItems = partitionAvatarGroupItems({
    items: Array.isArray(ticketDetails?.assignees)
      ? ticketDetails.assignees
      : [],
  });

  return (
    <div className={style.container}>
      <TicketDescriptionModal
        open={isExpanded}
        onClose={() => setIsExpanded(false)}
        ticketDetails={ticketDetails}
      />
      <div className={style.title}>
        <Body1Strong className={style.title_text}>
          {ticketDetails?.ticket_summary}
        </Body1Strong>
        <Caption1>
          {isoToDateAndTime(ticketDetails.created_at, {
            format: "DD MMMM YYYY",
          })}
        </Caption1>
        <div className={style.status_priority}>
          <StatusBadge
            id={ticketDetails?.ticket_status}
            name={ticketDetails?.status_name}
          />

          <PriorityBadge priority={ticketDetails?.priority} />
        </div>
        <div>
          <div className={style.quill_container}>
            <div id="quill-editor" className="custom_quill_body">
              <ReactQuill
                readOnly
                style={{
                  // height: "100%",
                  maxHeight: "400px",
                  overflow: "hidden",
                  border: "none",
                  whiteSpace: "pre-line",
                }}
                theme="snow"
                value={ticketDetails?.ticket_description}
                onChange={() => {}}
                modules={{
                  toolbar: false,
                }}
              />
            </div>
          </div>
          <div className={style.more_space}>
            <Button
              style={{ color: tokens.colorCompoundBrandBackground }}
              appearance="transparent"
              onClick={() => setIsExpanded(true)}
            >
              read more
            </Button>
          </div>
        </div>
        <div className={style.attachment_box}>
          {(Array.isArray(ticketDetails?.attachments)
            ? ticketDetails.attachments
            : []
          ).map((item: any) =>
            item?.access_specifier === "public" ? (
              <Attachment
                isStretched={true}
                title={item?.original_name}
                size={item?.size}
                type={item?.file_type}
                fileUrl={item?.url}
              />
            ) : null
          )}
        </div>
        <Body1Stronger>Assignees</Body1Stronger>
        <AvatarGroup
          layout="spread"
          size={28}
          style={{
            width: "100%",
            padding: "4px 0px",
          }}
        >
          {partitionedItems.inlineItems.map((assignee: any) => (
            <AvatarGroupItem
              name={getFullName({
                firstName: assignee?.first_name,
                lastName: assignee?.last_name,
              })}
              key={assignee?.resource_id}
              image={{
                src: assignee?.profile_image,
              }}
            />
          ))}
          {partitionedItems.overflowItems && (
            <AvatarGroupPopover>
              {partitionedItems.overflowItems.map((assignee: any) => (
                <AvatarGroupItem
                  name={getFullName({
                    firstName: assignee?.first_name,
                    lastName: assignee?.last_name,
                  })}
                  key={assignee?.resource_id}
                  image={{
                    src: assignee?.profile_image,
                  }}
                />
              ))}
            </AvatarGroupPopover>
          )}
        </AvatarGroup>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    alignItems: "start",
    ...shorthands.flex(1),
    ...shorthands.overflow("hidden", "scroll"),
  },
  quill_container: {
    "& .ql-editor": {
      maxHeight: "150px",
      paddingX: "0px",
      ...shorthands.overflow("hidden"),
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightRegular,
      color: tokens.colorNeutralForeground2,
    },
    "& .ql-toolbar.ql-snow": {
      p: "0px !important",
      m: "0px !important",
    },
    "& .ql-container.ql-snow": {
      backgroundColor: "transparent !important",
      ...shorthands.overflow("scroll"),
      ...shorthands.border("none !important"),
      minHeight: "0px",
      ...shorthands.border("none"),
    },
    "& .custom_quill_body": {
      height: "max-content",
      width: "100%",
      // maxHeight: "400px",
      ...shorthands.flex("1"),
      ...shorthands.padding("0px 0px"),
    },
  },
  title: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding("16px"),
    color: tokens.colorNeutralForeground2Link,
    ...shorthands.gap("12px"),
  },
  title_text: {
    textOverflow: "ellipsis",
    maxLines: "3",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },
  status_priority: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("16px"),
  },
  attachment_box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
  },
  more_space: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
  description: {
    width: "100%",
    maxHeight: "140px",
    ...shorthands.overflow("hidden"),
  },
  description_detail: {
    width: "100%",
    height: "fit-content",
    ...shorthands.overflow("scroll"),
  },
  summary: {
    maxHeight: "300px",
  },
  space: {
    width: "40px",
    height: "40px",
    backgroundColor: "red",
  },
});
