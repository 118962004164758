import React, { useContext, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TeamsFxContext } from "controller";
import { makeStyles,mergeClasses,shorthands,tokens } from "@fluentui/react-components";
import { extractStringFromHtml } from "Utils";
interface QuillEditorProps {
  onChange?: (value: string) => void;
  value?: string;
  required?: boolean;
  placeholder: string;
  error?: boolean;
  errorMessage?:string;
}
export const QuillEditor = ({
  placeholder,
  error,
  onChange,
  required,
  value,
  errorMessage,
}: QuillEditorProps) => {
  const style=useStyle();
  const { themeString } = useContext(TeamsFxContext);
  const quilllBottom = useRef<any>();
  let quillRef: any;
  const handleSizeChange = (e: any) => {
    const size = e.target.value;
    const quill = quillRef?.getEditor(); // Get the Quill instance
    quill.format("size", size); // Apply the selected size
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      ["bold", "italic", "underline"],
      [{ size: ["small", false, "large", "huge"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
    ],
  };

  const formats = ["bold", "italic", "underline", "list"];

  const handleChange = (value: any) => {
    onChange?.(extractStringFromHtml(value).length > 0 ? value : "");
  };

  return (
    <div className={mergeClasses(style.quilleditor, error && "error")}>
      <ReactQuill
        ref={(el) => {
          quillRef = el;
        }}
        style={{
          height: "300px",
        }}
        theme="snow"
        value={value}
        modules={modules}
        placeholder={placeholder}
        onChange={(value) => handleChange(value)}
      />
    </div>
  );
};

const useStyle=makeStyles({
  quilleditor:{
    marginTop: "20px",
    minHeight: "200px",
    maxHeight: "300px",
    "& .ql-editor::before": {
    content: "attr(placeholder)",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal !important",
    },
    "& .ql-container": {
      backgroundColor: tokens.colorNeutralBackground5,
      minHeight: "300px",
      maxHeight: "400px",
      ...shorthands.border("1px", "solid",tokens.colorNeutralBackground5),
      ...shorthands.borderTop("0px"),
    },
    "&.error .quill " :{
      "& .ql-container": {
        ...shorthands.border("1px", "solid",  tokens.colorStatusDangerBackground3),
      },
      // "& .ql-toolbar.ql-snow":{
      //   ...shorthands.border("1px", "solid",  `${tokens.colorStatusDangerBackground3} !important`),
      // }
    },
    "& .ql-toolbar.ql-snow":{
"& .ql-picker":{
  color:tokens.colorNeutralForeground3Hover,
  ":hover":{
    color:tokens.colorCompoundBrandForeground1
  },
  "&.ql-expanded":{
    color:tokens.colorNeutralForeground3Hover,
  },
  "& .ql-picker-label:hover":{
    color:tokens.colorCompoundBrandForeground1,
    "& .ql-stroke":{
      stroke:tokens.colorCompoundBrandForeground1
    }
  },
  "& .ql-picker-options":{
    backgroundColor:tokens.colorNeutralBackground1,
    "& .ql-picker-item:hover":{
      color:tokens.colorCompoundBrandForeground1,
      "& .ql-stroke,& button.ql-active .ql-stroke":{
        stroke:tokens.colorCompoundBrandForeground1
      },
    },
    "& .ql-picker-item.ql-selected::before":{
      color:tokens.colorCompoundBrandForeground1,
      "& .ql-stroke,& button.ql-active .ql-stroke":{
        stroke:tokens.colorCompoundBrandForeground1
      },
    }
  }
},
"& .ql-fill":{
  fill:tokens.colorNeutralForeground3Hover,
  stroke:"none",
},
"& .ql-stroke":{
  stroke:tokens.colorNeutralForeground3Hover,
  fill:"none",
},
"& .ql-active":{
  color:tokens.colorCompoundBrandForeground1
},

"& button:hover .ql-stroke,& button.ql-active .ql-stroke":{
  stroke:tokens.colorCompoundBrandForeground1
},
    },
    "& .ql-editor > p":{
      color:tokens.colorNeutralForeground1
    }
  },
});