import { TeamsFxContext, useGlobalStore } from "controller";

import {
  Avatar,
  Button,
  Caption1,
  Card,
  CardHeader,
  Link,
  Text,
  Tooltip,
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import { SignOut20Regular } from "@fluentui/react-icons";
import { lazy, useContext, useState } from "react";
import { LogoutModal } from "components";

const LoginPage = lazy(() => import("./loginPage"));

const AccountWrapper = ({
  msteamsData,
  loginCompleteHandler,
  userData,
}: {
  userData: any;
  msteamsData?: any;
  loginCompleteHandler: () => void;
}) => {
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false);
  const setCredentialdata = useGlobalStore((state) => state.setCredentialData);
  const setCurrentUser = () => {
    setCredentialdata({
      customerId: userData?.customerId,
      userId: userData?.customerUserId,
      token: userData?.token,
      isPermissionAllowed: Boolean(userData?.isPermissionAllowed),
    });
  };

  // console.log("🚀 ~ userData:", userData);
  const { themeString } = useContext(TeamsFxContext);
  const style = useStyle();
  return userData?.token ? (
    <div className={style.login_screen}>
      {isSignoutModalOpen && (
        <LogoutModal
          isOpen={isSignoutModalOpen}
          onClose={() => setIsSignoutModalOpen(false)}
          aadId={msteamsData?.objectId || ""}
          customerUserId={userData?.customerUserId}
          token={userData?.token || ""}
        />
      )}
      <div
        className={mergeClasses(
          style.login_container,
          themeString === "dark" && style.container_black
        )}
      >
        <Text weight="bold" size={700}>
          Welcome
        </Text>
        <div className={style.field_box}>
          <Card
            className={style.card}
            orientation="horizontal"
            onClick={setCurrentUser}
          >
            <CardHeader
              image={
                <Avatar
                  color="colorful"
                  name={userData?.displayName}
                  key={userData?.customerUserId}
                  image={{
                    src: userData?.profilePicture,
                  }}
                />
              }
              header={<Text weight="semibold">{userData?.displayName}</Text>}
              description={<Caption1>{userData?.emailId}</Caption1>}
              action={
                <Tooltip content={"Sign out"} relationship="label">
                  <Button
                    about="Signout"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setIsSignoutModalOpen(true);
                    }}
                    appearance="transparent"
                    icon={<SignOut20Regular />}
                    aria-label="More options"
                  />
                </Tooltip>
              }
            />
          </Card>
        </div>
        <div className={style.help_section}>
          <Link
            onClick={(e: any) => {
              e.stopPropagation();
              setIsSignoutModalOpen(true);
            }}
          >
            Sign in as another user?
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <LoginPage
      loginCompleteHandler={loginCompleteHandler}
      msTeamsData={msteamsData}
    />
  );
};

export default AccountWrapper;

const useStyle = makeStyles({
  login_screen: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  login_container: {
    maxWidth: "450px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    ...shorthands.borderRadius("24px"),
    ...shorthands.gap("24px"),
    ...shorthands.padding("50px", "70px"),
  },
  horizontalCardImage: {
    width: "32px",
    height: "32px",
    aspectRatio: "1",
    ...shorthands.padding("0px", "0px", "0px", "8px"),
  },
  card: {
    width: "360px",
    maxWidth: "100%",
    height: "fit-content",
    "&:hover": {
      backgroundColor: "#dcdcdc58",
      cursor: "pointer",
    },
  },
  container_black: { backgroundColor: "black" },
  field_box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("12px"),
  },
  input: {
    width: "100%",
    backgroundColor: "#dcdcdc58 !important",
  },
  login_button: { width: "100%", marginTop: "8px" },
  link: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    color: "#5563F5",
  },
  help_section: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "space-between",
    ...shorthands.gap("8px"),
  },
});
