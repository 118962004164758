import { Caption1Strong } from "@fluentui/react-components";
import { Flag20Regular } from "@fluentui/react-icons";
export const PriorityBadge = ({
  priority,
}: {
  priority: "critical" | "high" | "medium" | "low";
}) => {
  const status = PriorityBadgeArray.find((item) => item.value === priority) || {
    value: "low",
    backgroundColor: "#EDEDED",
    color: "#1C1E26",
  };
  // console.log("🚀 ~ priority:", priority, status);
  return (
    <div
      style={{
        width: "90px",
        backgroundColor: status.backgroundColor,
        color: status.color,
        padding: "4px 8px",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        height: "28px",
      }}
    >
      <Flag20Regular />
      <Caption1Strong>{status.value}</Caption1Strong>
    </div>
  );
};

const PriorityBadgeArray = [
  {
    value: "critical",
    backgroundColor: "#FFE8E5",
    color: "#E34935",
  },

  {
    value: "high",
    backgroundColor: "#FFE6D5",
    color: "#FF781F",
  },
  {
    value: "medium",
    backgroundColor: "#F8F1CF",
    color: "#FFA500",
  },
  {
    value: "low",
    backgroundColor: "#EDEDED",
    color: "#1C1E26",
  },
];
