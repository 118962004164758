export const priorities = [
  { label: "Critical", value: "critical" },
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

export const CLOSED_TICKET = "da5be1f9-db9d-4c64-8e43-51b458746773";

export const CANCELLED_TICKET = "953b24ef-2167-49f9-88d9-a06cca165753";

export const MERGED_TICKET = "caf3b9ab-08a5-4014-938c-aacc021f043b";

export const DISABLED_STATUS = [CLOSED_TICKET, CANCELLED_TICKET, MERGED_TICKET];
