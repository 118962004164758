import { create } from "zustand";
import { GlobalInterface, globalSlice } from "./global-store";
import { persist, createJSONStorage } from "zustand/middleware";

export const useGlobalStore = create<GlobalInterface>()(
  persist(
    (...a) => ({
      ...globalSlice(...a),
    }),
    {
      name: "global-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
