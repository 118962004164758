import { useQuery } from "@tanstack/react-query";
import { getSubscriptionStatus } from "controller/api";

export const useGetSubscriptionStatus = (token: string) => {
  return useQuery({
    enabled: !!token,
    queryKey: ["subscription"],
    queryFn: () => getSubscriptionStatus(),
  });
};
