import { AttachmentFileData } from "shared";
import { Modal } from "../Modal";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { uploadTicketAttachments } from "controller/api";
import { useMainStore } from "controller/store/useMainStore";
import { Box } from "components/Box";
import { AttachmentDragAndDrop } from "./AttachmentDragAndDrop";
import { Stack } from "components/Stack";
import { AttachmentTile } from "./AttachmentTile";

interface AttachmentModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: (data: Array<AttachmentFileData>) => void;
}

export const AttachmentModal = ({
  open,
  onClose,
  onSubmit,
}: AttachmentModalProps) => {
  const [fileData, setFileData] = useState<Array<AttachmentFileData>>([]);
  const addAttachment = useMainStore((state) => state.setAttachmentModalData);
  const attachmentFromStore = useMainStore(
    (state) => state.attachmentModalData.attachments
  );
  const [completedFileCount, setCompletedFileCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const removeImageFromList = (itemIndex: number) => {
    setFileData((prev) => prev.filter((item, index) => itemIndex != index));
  };

  useEffect(() => {
    if (completedFileCount === fileData.length) {
      setIsUploading(false);
    }
  }, [completedFileCount]);

  useEffect(() => {
    if (attachmentFromStore) {
      const uploadedFileLength = attachmentFromStore.filter(
        (item: any) => item.status === "Uploaded"
      ).length;
      setCompletedFileCount(uploadedFileLength);
      setFileData(attachmentFromStore);
    }
  }, [attachmentFromStore]);

  const onDrop = useCallback((acceptedFiles: any) => {
  
    const data: Array<AttachmentFileData> = acceptedFiles?.map((file: any) => {
      const key = uuidv4();

      return {
        key: key,
        name: file.name,
        size: file.size,
        type: file.type,
        status: "Uploading",
        progress: 0,
        file,
      };
    });
    setFileData((prev) => {
      return [...prev, ...data];
    });
    setIsUploading(true);
    data.map((file) => {
      uploadImage({ file: file.file, key: file.key });
    });
  }, []);

  const uploadImage = ({ file, key }: { file: any; key: string }) => {
    const formData = new FormData();
    formData.append("files", file);

    uploadTicketAttachments({ formData }).subscribe({
      next: ({ response, status, percentage, controller }: any) => {
        if (status === "COMPLETE") {
          if (response?.success && response?.statusCode === 200) {
            setCompletedFileCount((prev) => prev + 1);
            setFileData((prev) => {
              const updatedData = [...prev];
              const index = updatedData.findIndex((file) => file.key === key);

              if (index !== -1) {
                updatedData[index] = {
                  ...updatedData[index],
                  status: "Uploaded",
                  progress: 100,
                  responseData: response.data[0],
                };
                return updatedData;
              }
              return updatedData;
            });
          } else {
            setCompletedFileCount((prev) => prev + 1);
            setFileData((prev) => {
              const updatedData = [...prev];
              const index = updatedData.findIndex((file) => file.key === key);

              if (index !== -1) {
                updatedData[index] = {
                  ...updatedData[index],
                  status: "Error",
                  progress: 0,
                };
                return updatedData;
              }
              return updatedData;
            });
          }
        } else {
          setFileData((prev) => {
            const updatedData = [...prev];
            const index = updatedData.findIndex((file) => file.key === key);
            if (index !== -1) {
              updatedData[index] = {
                ...updatedData[index],
                status: "Uploading",
                progress: percentage,
              };
              return updatedData;
            }
            return updatedData;
          });
        }
      },
    });
  };

  const handlePrimaryButtonClick = () => {
    onSubmit?.(fileData);
    addAttachment(fileData);
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      isPrimaryButtonDisabled={
        completedFileCount !== fileData.length && isUploading
      }
      isSecondaryButtonDisabled={
        completedFileCount !== fileData.length && isUploading
      }
      secondaryButtonLabel="Cancel"
      onSecondaryButtonClick={onClose}
      primaryButtonLabel="Save"
    >
      <Box
        sx={{
          padding: "0px 16px",
          width: "100%",
        }}
      >
        <AttachmentDragAndDrop onDrop={onDrop} />
        <Box
          sx={{
            marginTop: "16px",
            minHeight: "200px",
          }}
        >
          <Stack>
            {fileData.map((file, index) => (
              <AttachmentTile
                key={file.key}
                file={file}
                percentage={file.progress}
                status={file.status}
                onRemove={() => removeImageFromList(index)}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
