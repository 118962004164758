interface BoxProps {
  width?: string;
  height?: string;
  children?: React.ReactNode;
  flex?: string | number;
  sx?: React.CSSProperties | undefined;
  className?: string;
}
export const Box = ({
  children,
  height,
  sx = {},
  width,
  flex,
  className,
}: BoxProps) => (
  <div className={className} style={{ width, height, flex, ...sx }}>
    {children}
  </div>
);
