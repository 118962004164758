import { axiosClient } from "Utils";
import { NotificationInput } from "shared";
const baseUrl = process.env.REACT_APP_BOT_BASE_URL;
export const createTeamsNotification = async (data: NotificationInput) => {
  try {
    const response = await axiosClient.post(
      `${baseUrl}/api/usernotification`,
      data
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to create ticket at the moment");
  }
};
