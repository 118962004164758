import { Caption1, Combobox, Option, Persona } from "@fluentui/react-components";
import { getFullName } from "Utils";
import { useEffect, useState } from "react";

export const MemberComboBox = ({
  members,
  onSubmit,
  selectedUsers,
  className,
}: {
  className?: any;
  members: Array<any>;
  selectedUsers: Array<string>;
  onSubmit: (selectedUsers: Array<string>) => void;
}) => {
  const [selectedUserOptions, setSelectedUserOptions] =
    useState<string[]>(selectedUsers);
  const [userValue, setUserValue] = useState("");

  useEffect(() => {
    setLabelValue();
  }, [selectedUsers]);

  const setLabelValue = () => {
    let result = selectedUserOptions.map((user) => {
      if (Array.isArray(members)) {
        const member = members.find((item) => item?.customer_user_id === user);
        if (member) {
          return getFullName({
            firstName: member?.first_name,
            lastName: member?.last_name,
            middleName: "",
          });
        }
      }
      return "";
    });
    setUserValue(result.length > 0 ? result.join(", ") : "All");

    result = [];
  };

  const onSelect = (event: any, data: any) => {
    setSelectedUserOptions(data.selectedOptions);
    setUserValue("");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserValue(event.target.value);
  };

  return (
    <Combobox
      appearance="filled-lighter"
      className={className}
      multiselect={true}
      placeholder="Search team members"
      value={userValue}
      onChange={onChange}
      onOptionSelect={onSelect}
      selectedOptions={selectedUserOptions}

      onBlur={() => {
        setLabelValue();
        onSubmit(selectedUserOptions);
      }}
      onFocus={() => {
        setUserValue("");
      }}
    >
      {Array.isArray(members)&& members.length>0?members.map((option) => (
        <Option
          key={option?.customer_user_id}
          text={getFullName({
            firstName: option?.first_name,
            lastName: option?.last_name,
            middleName: "",
          })}
          value={option?.customer_user_id}
        >
          {
            <Persona
              name={getFullName({
                firstName: option?.first_name,
                lastName: option?.last_name,
                middleName: "",
              })}
              avatar={{
                image: {
                  src: option?.profile_image,
                },
                color: "colorful",
              }}
            />
          }
        </Option>
      )):     <div><Caption1>No members available</Caption1></div>}
    </Combobox>
  );
};
