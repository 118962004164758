import {
  Avatar,
  Button,
  SelectTabData,
  SelectTabEvent,
  Subtitle1,
  Tab,
  TabList,
  TabValue,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CancelTicketModal, LeftSideBar, TicketChat } from "./components";
import {
  MqttContext,
  useCloseTicketById,
  useGetTicketById,
  useMainStore,
  useReopenTicketById,
} from "controller";
import { pages } from "@microsoft/teams-js";
import {
  ArrowLeft20Regular,
  ChevronLeft24Regular,
} from "@fluentui/react-icons";
import { getFullName } from "Utils";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import { ReopenTicketModal } from "./components/ReopenTicketModal";
import {
  CANCELLED_TICKET,
  CLOSED_TICKET,
  DISABLED_STATUS,
  MERGED_TICKET,
} from "shared";

const TicketDetailsPage = () => {
  const style = useStyle();
  const [searchParam, setSearchParam] = useSearchParams();
  const resetAttachments = useMainStore(
    (state) => state.resetAttachmentModalData
  );
  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });
  const ticketId = searchParam.get("ticketId");
  const { mutateAsync: closeTicket } = useCloseTicketById();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabValue>("overview");
  const { mutateAsync: reopenTicket } = useReopenTicketById();
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };
  const {
    data: ticketDetails,
    isFetching: isTicketDetailsFetching,
    isLoading: isTicketDetailsLoading,
  } = useGetTicketById(ticketId);

  useEffect(() => {
    getData();
    if (!ticketId) {
      setSearchParam({
        route: "home",
      });
    }
    return () => {
      resetAttachments();
    };
  }, [ticketId]);

  useEffect(() => {
    if (!isTicketDetailsLoading && !ticketDetails) {
      onBackClick();
    }
  }, [ticketDetails, isTicketDetailsLoading]);

  const onBackClick = () => {
    setSearchParam({
      route: "home",
    });
  };

  const getData = async () => {
    const res = await pages.getConfig();
  };

  const { connectMqtt, subscribeRoom, isConnected, unSubscribeRoom } =
    useContext(MqttContext);

  useEffect(() => {
    if (!isConnected) {
      connectMqtt();
    } else {
      subscribeRoom([`tickets/${ticketId}`]);
    }

    return () => {
      if (isConnected) {
        unSubscribeRoom([`tickets/${ticketId}`]);
      }
    };
  }, [isConnected]);

  const closeTicketHandler = async () => {
    setIsCancelModalOpen(false);
    try {
      if (ticketId) {
        await closeTicket(ticketId);
        toast.success("Ticket cancelled successfully");
      }
    } catch (e) {
      toast.error("Unable to cancel ticket at the moment");
    }
  };

  const reOpenTicketHandler = async () => {
    setIsReopenModalOpen(false);
    try {
      if (ticketId) {
        await reopenTicket(ticketId);
        toast.success("Ticket reopened successfully");
      }
    } catch (e) {
      toast.error("Unable to reopened ticket at the moment");
    }
  };

  return (
    <div className={style.ticket_details_container}>
      <CancelTicketModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onSubmit={closeTicketHandler}
      />
      {
        <ReopenTicketModal
          isOpen={isReopenModalOpen}
          onClose={() => setIsReopenModalOpen(false)}
          onSubmit={reOpenTicketHandler}
        />
      }
      {!isTicketDetailsLoading
        ? ticketDetails && (
            <div className={style.gridBody}>
              {!isMobile && (
                <div className={style.leftSideBar}>
                  <div className={style.header}>
                    <Subtitle1 className={style.title}>
                      {ticketDetails.u_id}
                    </Subtitle1>
                    {!isMobile &&
                      ticketDetails?.ticket_status !== MERGED_TICKET &&
                      (ticketDetails?.ticket_status === CLOSED_TICKET ? (
                        <Button
                          appearance="outline"
                          size="medium"
                          onClick={() => setIsReopenModalOpen(true)}
                        >
                          Reopen ticket
                        </Button>
                      ) : (
                        ticketDetails?.ticket_status !== CANCELLED_TICKET && (
                          <Button
                            appearance="outline"
                            size="medium"
                            onClick={() => setIsCancelModalOpen(true)}
                          >
                            Cancel ticket
                          </Button>
                        )
                      ))}
                  </div>
                  <LeftSideBar ticketDetails={ticketDetails} />
                </div>
              )}
              <div className={style.chatContainer}>
                <div className={style.header}>
                  <Button
                    appearance="transparent"
                    onClick={onBackClick}
                    icon={<ArrowLeft20Regular />}
                  />
                  <Avatar
                    color="colorful"
                    name={getFullName({
                      firstName: ticketDetails?.customer_user_first_name,
                      lastName: ticketDetails?.customer_user_last_name,
                    })}
                    badge={{ status: "available" }}
                    key={ticketDetails?.customer_user_id}
                    image={{
                      src: ticketDetails?.customer_user_profile_image,
                    }}
                  />
                  <Subtitle1 className={style.title}>
                    {getFullName({
                      firstName: ticketDetails?.customer_user_first_name,
                      lastName: ticketDetails?.customer_user_last_name,
                    })}
                  </Subtitle1>
                  <div className="right_side_chat_ticket_close_button">
                    {isMobile &&
                      ticketDetails?.ticket_status !== MERGED_TICKET &&
                      (ticketDetails?.ticket_status === CLOSED_TICKET ? (
                        <Button
                          appearance="outline"
                          size="medium"
                          onClick={() => setIsReopenModalOpen(true)}
                        >
                          Reopen ticket
                        </Button>
                      ) : (
                        ticketDetails?.ticket_status !== CANCELLED_TICKET && (
                          <Button
                            appearance="outline"
                            size="medium"
                            onClick={() => setIsCancelModalOpen(true)}
                          >
                            Cancel ticket
                          </Button>
                        )
                      ))}
                  </div>
                </div>

                {ticketId &&
                  (isMobile ? (
                    <>
                      <TabList
                        selectedValue={selectedTab}
                        onTabSelect={onTabSelect}
                      >
                        <Tab id="overview" value="overview">
                          Overview
                        </Tab>
                        <Tab id="chat" value="chat">
                          Chat
                        </Tab>
                      </TabList>
                      {selectedTab === "chat" ? (
                        <TicketChat
                          serviceId={ticketId}
                          ticketDetails={ticketDetails}
                          isDisabled={DISABLED_STATUS.includes(
                            ticketDetails?.ticket_status
                          )}
                        />
                      ) : (
                        <LeftSideBar ticketDetails={ticketDetails} />
                      )}
                    </>
                  ) : (
                    <TicketChat
                      serviceId={ticketId}
                      ticketDetails={ticketDetails}
                      isDisabled={DISABLED_STATUS.includes(
                        ticketDetails?.ticket_status
                      )}
                    />
                  ))}
              </div>
            </div>
          )
        : null}
    </div>
  );
};

export default TicketDetailsPage;

const useStyle = makeStyles({
  ticket_details_container: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.padding("14px", "20px"),
    ...shorthands.gap("12px"),
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralBackground6),
  },

  title: {
    fontWeight: 700,
    ...shorthands.flex(1),
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },

  gridBody: {
    flexDirection: "row",
    display: "flex",
    ...shorthands.flex(1),
    width: "100%",
  },
  leftSideBar: {
    width: "350px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.borderRight("1px", "solid", tokens.colorNeutralBackground6),
  },
  chatContainer: {
    ...shorthands.flex(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});
