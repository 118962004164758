import { create } from "zustand";
import { CommonDataInterface, CommonDataSlice } from "./main-store";
import { createJSONStorage, persist } from "zustand/middleware";

export const useMainStore = create<CommonDataInterface>()(
  persist((...a) => ({ ...CommonDataSlice(...a) }), {
    name: "main-store",
    storage: createJSONStorage(() => sessionStorage),
  })
);
