import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Modal } from "components";
import ReactQuill from "react-quill";

export const TicketDescriptionModal = ({
  ticketDetails,
  open,
  onClose,
}: {
  ticketDetails: any;
  open: boolean;
  onClose: () => void;
}) => {
  const style = useStyle();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`ID:${ticketDetails?.u_id}`}
      hideFooter={true}
    >
      <div className={style.quill_container}>
        <div id="quill-editor" className="custom_quill_body">
          <ReactQuill
            readOnly
            style={{
              // height: "100%",
              maxHeight: "90vh",
              overflow: "hidden",
              border: "none",
              whiteSpace: "pre-line",
            }}
            theme="snow"
            value={ticketDetails?.ticket_description}
            onChange={() => {}}
            modules={{
              toolbar: false,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const useStyle = makeStyles({
  quill_container: {
    "& .ql-editor": {
      maxHeight: "90vh",
      paddingX: "0px",
      ...shorthands.overflow("scroll"),
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightRegular,
      color: tokens.colorNeutralForeground2,
    },
    "& .ql-toolbar.ql-snow": {
      p: "0px !important",
      m: "0px !important",
    },
    "& .ql-container.ql-snow": {
      backgroundColor: "transparent !important",
      ...shorthands.overflow("scroll"),
      ...shorthands.border("none !important"),
      minHeight: "0px",
      ...shorthands.border("none"),
    },
    "& .custom_quill_body": {
      height: "max-content",
      width: "100%",
      // maxHeight: "400px",
      ...shorthands.flex("1"),
      ...shorthands.padding("0px 0px"),
    },
  },
});
