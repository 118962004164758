import { axiosClient } from "Utils";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
export const getSubscriptionStatus = async () => {
  try {
    const response = await axiosClient.get(`/v1/agent/subscription/status`);
    const resData = response.data;

    if (
      resData.statusCode === 200 &&
      resData.success === true &&
      resData.data
    ) {
      return resData.data;
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get subscription status");
  }
};

export const getSubscriptionStatusByMutate = async (apiToken: string) => {
  try {
    const response = await axios.get(
      `${baseUrl}/v1/agent/subscription/status`,
      {
        headers: {
          "X-API-KEY": `${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    const resData = response.data;

    if (
      resData.statusCode === 200 &&
      resData.success === true &&
      resData.data
    ) {
      return resData.data;
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get subscription status");
  }
};
