import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const AttachIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g clip-path="url(#a)">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
          stroke-width="1.5"
          d="m13.879 8.375-5.486 5.486a2.116 2.116 0 0 0 0 2.992v0a2.116 2.116 0 0 0 2.992 0l7.232-7.232a3.879 3.879 0 0 0 0-5.486v0a3.879 3.879 0 0 0-5.486 0l-7.232 7.232a5.642 5.642 0 0 0 0 7.98v0a5.642 5.642 0 0 0 7.98 0l4.389-4.389"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
