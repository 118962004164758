import {
  Field,
  Input as FluentInput,
  InputProps as FluentInputProps,
} from "@fluentui/react-components";
import { Control, Controller, FieldValues } from "react-hook-form";
import { TextField } from "@fluentui/react";
import { error } from "console";

interface InputProps extends FluentInputProps {
  fullWidth?: boolean;
  label?: string;
  control?: any;
  name: string;
  error?: boolean;
  errorMessage?: string;
}
export const Input = ({
  name,
  control,
  style,
  errorMessage,
  error,
  ...props
}: InputProps) => {
  return <Field
  validationState={error?"error":"none"}
      validationMessage={errorMessage}
  >{
    control ? (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, onBlur, value, name } }) => (
        <FluentInput
          ref={ref}
          name={name}
          onBlur={onBlur}
          appearance="outline"
          style={{
            borderBottom: "none ! important",
            width: props.fullWidth ? "100%" : "100px",
            ...style,
          }}
          {...props}
          value={value}
          onChange={onChange}
        />
      )}
    />
  ) : (
    <FluentInput
      appearance="outline"
      style={{
        borderBottom: "none ! important",
        width: props.fullWidth ? "100%" : "100px",
        ...style,
      }}
      {...props}
    />
  )}
  </Field>;
};
