import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export const getFilterDateValue = (filterdata?: string) => {
  switch(filterdata?.toLocaleLowerCase())
  {
  case "today":
  return dayjs().endOf("day").toISOString();
case "last 7 days":
  return dayjs().subtract(7, "days").endOf("day").toISOString();
  case "last 30 days":
  return dayjs().subtract(30, "days").endOf("day").toISOString();
  case "last 3 months":
  return dayjs().subtract(3, "months").endOf("day").toISOString();
  case "last 6 months":
  return dayjs().subtract(6, "months").endOf("day").toISOString();
  case "last 12 months":
  return dayjs().subtract(12, "months").endOf("day").toISOString();
  default:
  return dayjs().subtract(30, "days").endOf("day").toISOString();
  }
};

interface isoFormatProps {
  format?: string;
  timezone?: string;
}
export const isoToDateAndTime = (isodate: string, props?: isoFormatProps) => {
  try {
    if (isodate) {
      let formattedDate = dayjs(isodate);
      if (props?.timezone) {
        formattedDate = formattedDate.tz(props?.timezone);
      }
      return formattedDate.format(props?.format || "DD MMM YYYY");
    }
    return "";
  } catch (e) {
    return "";
  }
};
