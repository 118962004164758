import {
  BrandVariants,
  Theme,
  createLightTheme,
  createDarkTheme,
} from "@fluentui/react-components";

const myNewTheme: BrandVariants = {
  10: "#030206",
  20: "#161426",
  30: "#212045",
  40: "#2A2A5F",
  50: "#33357A",
  60: "#3B4097",
  70: "#444BB4",
  80: "#4C56D3",
  90: "#5462F2",
  100: "#6B70F7",
  110: "#8180F9",
  120: "#948FFA",
  130: "#A69FFB",
  140: "#B7B0FD",
  150: "#C7C0FE",
  160: "#D7D1FE",
};

export const lightTheme: Theme = {
  ...createLightTheme(myNewTheme),
};

export const darkTheme: Theme = {
  ...createDarkTheme(myNewTheme),
};

darkTheme.colorBrandForeground1 = myNewTheme[110]; // use brand[110] instead of brand[100]
darkTheme.colorBrandForeground2 = myNewTheme[120];
