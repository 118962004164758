import {
  Button,
  Caption1Strong,
  Subtitle1,
  Textarea,
  Title2,
  Title3,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { RatingIconButton } from "./RatingIconButton";
import { useState } from "react";
import { RatingViewer } from "components/RatingViewer";
import { useAddFeedback, useGlobalStore } from "controller";
import toast from "react-hot-toast";

export const RatingBox = ({
  message,
  rating,
  serviceId,
}: {
  rating?: number;
  message?: string;
  serviceId: string;
}) => {
  const style = useStyle();

  const [userRating, setUserRating] = useState<any>(rating);

  const [feedback, setFeedBack] = useState<any>(message);

  const customerUserId = useGlobalStore((state) => state.userId);

  const { mutateAsync: addFeedback } = useAddFeedback();

  const onSubmit = async () => {
    try {
      await addFeedback({
        customer_user_id: customerUserId,
        feedback: feedback,
        rating: userRating,
        serviceId: serviceId,
      });
      toast.success("Feedback added successfully");
    } catch (e: any) {
      toast.error(e.message || "Unable to add feedback at the moment");
    }
  };

  return (
    <div className={style.root}>
      <Subtitle1>How would you rate your experience ?</Subtitle1>
      {rating ? (
        <>
          <RatingIconButton
            isSelected={true}
            onClick={() => {}}
            rating={userRating}
            className={style.feedback_icon}
          />
          <RatingViewer
            rating={userRating}
            labelOnly
            labelClassName={style.rating_label}
          />

          <div className={style.feedback_view}>{message}</div>
        </>
      ) : (
        <>
          <div className={style.rating_row}>
            {[...Array(5)].map((_, index: number) => (
              <RatingIconButton
                isSelected={userRating === index + 1}
                onClick={() => setUserRating(index + 1)}
                rating={index + 1}
              />
            ))}
          </div>
          {userRating && (
            <>
              <RatingViewer rating={userRating} labelOnly />
              <Textarea
                className={style.feedback_field}
                resize="vertical"
                placeholder="Let us know how you feel"
                value={feedback}
                onChange={(e) => setFeedBack(e.target.value)}
              />
              <div className={style.action}>
                <Button
                  appearance="secondary"
                  onClick={() => {
                    setFeedBack("");
                    setUserRating(null);
                  }}
                >
                  Cancel
                </Button>
                <Button appearance="primary" onClick={onSubmit}>
                  Submit
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "500px",
    height: "fit-content",
    ...shorthands.gap("16px"),
    ...shorthands.margin("auto", "auto"),
    ...shorthands.padding("32px", "32px"),
    ...shorthands.borderRadius("16px"),
    ...shorthands.border("1px", "solid", "grey"),
  },

  rating_row: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("16px"),
  },
  feedback_field: {
    width: "100%",
    maxWidth: "400px",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    width: "100%",
    maxWidth: "400px",
    alignItems: "end",
    ...shorthands.gap("16px"),
  },
  feedback_icon: {
    width: "50%",
    height: "none",
    aspectRatio: "1",
    ...shorthands.border("none"),
    ...shorthands.borderRadius("4px"),
  },
  rating_label: {
    fontSize: "18px",
    fontWeight: "700",
  },
  feedback_view: {
    backgroundColor: "#dcdcdc58",
    width: "100%",
    maxWidth: "400%",
    display: "flex",
    flexDirection: "column",
    minHeight: "80px",
    ...shorthands.borderRadius("16px"),
    ...shorthands.padding("16px"),
  },
});
