import { app } from "@microsoft/teams-js";
import { lazy, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Body1,
  Caption1,
  Caption2,
  Link,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useGlobalStore, useMainStore } from "controller";
import TicketDetailsPage from "../pages/modules/ticket/TicketDetailsPage";
import { HomePage } from "pages/modules/home/HomePage";
const botUrl = process.env.REACT_APP_BOT_BASE_URL;
const clientId = process.env.REACT_APP_AAD_APP_CLIENT_ID;
export const NavigationWrapper = () => {
  const style = useStyle();
  const [searchParam, setSearchParam] = useSearchParams();
  const isPermissionAllowed = useGlobalStore(
    (state) => state.isPermissionAllowed
  );
  const [route, setRoute] = useState({
    route: searchParam.get("route") || "home",
    params: searchParam,
  });
  const isSubscriptionAvailable = useMainStore(
    (state) => state.isSubscriptionAvailable
  );
  useEffect(() => {
    if (searchParam.get("route")) {
      setRoute({
        route: searchParam.get("route") || "home",
        params: searchParam,
      });
    } else {
      getRoute();
    }
  }, [searchParam]);

  useEffect(() => {
    if (!isSubscriptionAvailable) {
    }
  }, [isSubscriptionAvailable]);

  const onPermissionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const redirectUrl = botUrl + "/permission";
    const url = `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&state=1675345&redirect_uri=${redirectUrl}`;
    window.open(url, "_blank");
  };

  const onTeamsAdminCenterClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const url =
      "https://admin.teams.microsoft.com/policies/manage-apps/0c4388c5-bb07-433d-9ee1-d379351c6844/permission";
    window.open(url, "_blank");
  };

  const getRoute = async () => {
    try {
      const teamContext = await app.getContext();

      const routeDetails = teamContext?.page?.subPageId?.split("_");
      if (routeDetails && routeDetails.length > 0) {
        if (routeDetails[0] === "ticket" && routeDetails.length > 1) {
          setSearchParam({
            ticketId: routeDetails[1],
            route: "ticket",
          });
        }
      }
    } catch (e) {}
  };

  return (
    <div className={style.root}>
      {!isPermissionAllowed && (
        <div className={style.permission_header}>
          <Body1>
            Admins can grant chat notification permissions in the{" "}
            <Link onClick={onTeamsAdminCenterClick}>MS Teams Admin Center</Link>{" "}
            or click <Link onClick={onPermissionClick}>here</Link>
          </Body1>
        </div>
      )}
      <div className={style.body}>
        {route.route === "ticket" && route.params.get("ticketId") ? (
          <TicketDetailsPage />
        ) : (
          <HomePage />
        )}
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    width: "100%",
    ...shorthands.flex(1),
  },
  permission_header: {
    width: "100%",
    display: "flex",
    backgroundColor: tokens.colorNeutralBackground3,
    justifyContent: "center",
    ...shorthands.padding("4px", "16px", "4px", "16px"),
  },
});
