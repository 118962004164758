import { Caption1, makeStyles } from "@fluentui/react-components";
import { useEffect, useMemo } from "react";
import { shorthands } from "@fluentui/react-components";
import { RatingIcon } from "./RatingIcon";
export const RatingViewer = ({
  rating = 1,
  iconOnly,
  labelOnly,
  labelClassName,
}: {
  rating: number;
  iconOnly?: boolean;
  labelOnly?: boolean;
  labelClassName?: any;
}) => {
  // console.log("🚀 ~ RatingViewer ~ rating:", rating);
  const style = useStyle();
  let ratingString;

  if (rating === 2) {
    ratingString = "Need to improve";
  } else if (rating === 3) {
    ratingString = "Satisfied";
  } else if (rating === 4) {
    ratingString = "Good";
  } else if (rating >= 5) {
    ratingString = "Awesome";
  } else {
    ratingString = "Disappointed";
  }

  return (
    <div className={style.rating_box}>
      {!labelOnly && <RatingIcon rating={rating} />}
      {!iconOnly && (
        <Caption1 className={labelClassName}>{ratingString}</Caption1>
      )}
    </div>
  );
};

const useStyle = makeStyles({
  rating_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    ...shorthands.gap("8px"),
  },
});
