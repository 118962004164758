// export interface SvgIconPropsSizeOverrides {}

// export interface SvgIconPropsColorOverrides {}

// export interface SvgIconClasses {
//   /** Styles applied to the root element. */
//   root: string;
//   /** Styles applied to the root element if `color="primary"`. */
//   colorPrimary: string;
//   /** Styles applied to the root element if `color="secondary"`. */
//   colorSecondary: string;
//   /** Styles applied to the root element if `color="action"`. */
//   colorAction: string;
//   /** Styles applied to the root element if `color="error"`. */
//   colorError: string;
//   /** Styles applied to the root element if `color="disabled"`. */
//   colorDisabled: string;
//   /** Styles applied to the root element if `fontSize="inherit"`. */
//   fontSizeInherit: string;
//   /** Styles applied to the root element if `fontSize="small"`. */
//   fontSizeSmall: string;
//   /** Styles applied to the root element if `fontSize="medium"`. */
//   fontSizeMedium: string;
//   /** Styles applied to the root element if `fontSize="large"`. */
//   fontSizeLarge: string;
// }
// export type SvgIconClassKey = keyof SvgIconClasses;
// export declare function getSvgIconUtilityClass(slot: string): string;

// export interface SvgIconTypeMap<P = {}, D extends React.ElementType = "svg"> {
//   props: P & {
//     /**
//      * Node passed into the SVG element.
//      */
//     children?: React.ReactNode;
//     /**
//      * Override or extend the styles applied to the component.
//      */
//     classes?: Partial<SvgIconClasses>;
//     /**
//      * The color of the component.
//      * It supports both default and custom theme colors, which can be added as shown in the
//      * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
//      * You can use the `htmlColor` prop to apply a color attribute to the SVG element.
//      * @default 'inherit'
//      */
//     color?: Record<
//       | "inherit"
//       | "action"
//       | "disabled"
//       | "primary"
//       | "secondary"
//       | "error"
//       | "info"
//       | "success"
//       | "warning",
//       SvgIconPropsColorOverrides
//     >;
//     /**
//      * The fontSize applied to the icon. Defaults to 24px, but can be configure to inherit font size.
//      * @default 'medium'
//      */
//     fontSize?: Record<
//       "inherit" | "large" | "medium" | "small",
//       SvgIconPropsSizeOverrides
//     >;
//     /**
//      * Applies a color attribute to the SVG element.
//      */
//     htmlColor?: string;

//     inheritViewBox?: boolean;

//     shapeRendering?: string;

//     sx?: any;

//     titleAccess?: string;
//     /**
//      * Allows you to redefine what the coordinates without units mean inside an SVG element.
//      * For example, if the SVG element is 500 (width) by 200 (height),
//      * and you pass viewBox="0 0 50 20",
//      * this means that the coordinates inside the SVG will go from the top left corner (0,0)
//      * to bottom right (50,20) and each unit will be worth 10px.
//      * @default '0 0 24 24'
//      */
//     viewBox?: string;
//   };
//   defaultComponent: D;
// }

// declare const SvgIcon: OverridableComponent<SvgIconTypeMap> & {
//   muiName: string;
// };

// export type SvgIconProps<
//   D extends React.ElementType = SvgIconTypeMap["defaultComponent"],
//   P = {}
// > = OverrideProps<SvgIconTypeMap<P, D>, D>;

// export default SvgIcon;

export interface SvgIconProps {
  children?: JSX.Element;
  style?: React.CSSProperties | undefined;
}
export const SvgIcon = ({ children, style = {} }: SvgIconProps) => {
  return (
    <div
      style={{
        width: "24px",
        height: "24px",
        aspectRatio: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "currentcolor",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
