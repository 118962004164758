import { useMutation } from "@tanstack/react-query";
import {
  getApiToken,
  login,
  logout,
  postLogin,
  preLogin,
  resetPassword,
} from "controller/api";
import { PostLoginInput } from "shared";

export const usePreLogin = () => {
  return useMutation({
    mutationFn: (value: string) => preLogin(value),
  });
};

export const usePostLogin = () => {
  return useMutation({
    mutationFn: (value: PostLoginInput) => postLogin(value),
  });
};

export const useGetApiToken = () => {
  return useMutation({
    mutationFn: (value: string) => getApiToken(value),
  });
};

export const useLogin = () => {
  return useMutation({
    mutationFn: login,
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: logout,
  });
};
