import { GetQueryInput } from "shared";

export const getQueryDataObject = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  searchQuery,
  filter,
}: GetQueryInput) => {
  return {
    ...(perPageCount && { per_page: perPageCount.toString() }),
    ...(pageNumber && { page: pageNumber.toString() }),
    ...(sortColumn && { sortColumn }),
    ...(sortDirection && { sortDirection }),
    ...(searchQuery && { searchQuery }),
    ...{ filters: filter ? JSON.stringify(filter) : JSON.stringify([]) },
  };
};
