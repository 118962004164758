import {
  Avatar,
  Button,
  Divider,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Subtitle2Stronger,
  shorthands,
  makeStyles,
  useId,
  Caption2Strong,
  Body1,
} from "@fluentui/react-components";
import { useGlobalStore, useRemoveCustomerUser } from "controller";
import { useState } from "react";
import { SignOut20Regular } from "@fluentui/react-icons";
import toast from "react-hot-toast";
import { LogoutModal } from "components";
export const ProfileButton = () => {
  const styles = useStyles();
  const userName = useGlobalStore((state) => state.displayName);
  const userProfile = useGlobalStore((state) => state.profilePicture);
  const userEmail = useGlobalStore((state) => state.email);
  const msId = useGlobalStore((state) => state.msId);
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false);
  const id = useId();
  const userId = useGlobalStore((state) => state.userId);
  const token = useGlobalStore((state) => state.token);
  return (
    <>
      {isSignoutModalOpen && (
        <LogoutModal
          isOpen={isSignoutModalOpen}
          onClose={() => setIsSignoutModalOpen(false)}
          aadId={msId || ""}
          customerUserId={userId}
          token={token || ""}
        />
      )}
      <Popover trapFocus withArrow positioning={"below-end"}>
        <PopoverTrigger disableButtonEnhancement>
          <Avatar
            color="colorful"
            name={userName}
            badge={{ status: "available" }}
            key={userProfile}
            image={{
              src: userProfile,
            }}
          />
        </PopoverTrigger>

        <PopoverSurface aria-labelledby={id} className={styles.pop_surface}>
          <div className={styles.contentHeader}>
            <Subtitle2Stronger>{userName}</Subtitle2Stronger>
            <Body1>{userEmail}</Body1>
          </div>
          <Divider className={styles.divider} />
          <div>
            <Button
              appearance="transparent"
              onClick={() => setIsSignoutModalOpen(true)}
              icon={<SignOut20Regular />}
            >
              Log out
            </Button>
          </div>
        </PopoverSurface>
      </Popover>
    </>
  );
};

const useStyles = makeStyles({
  pop_surface: {
    ...shorthands.padding("0px"),
    ...shorthands.borderRadius("4px"),
    ...shorthands.overflow("clip"),
  },
  contentHeader: {
    backgroundColor: "rgb(231, 233, 254)",
    ...shorthands.padding("10px", "12px"),
    color: "black ! important",
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    backgroundColor: "grey",
  },
});
