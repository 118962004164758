import { IconButton } from "@fluentui/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { CloseIcon } from "components/icons";

interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  title?: string;
  onSecondaryButtonClick?: () => void;
  onPrimaryButtonClick?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  isClosable?: boolean;
  isLoading?: boolean;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  footerRightActionComponent?: React.ReactNode;
  hideFooter?: boolean;
}
export const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  children,
  onClose,
  title,
  onSecondaryButtonClick,
  onPrimaryButtonClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  isClosable = true,
  isLoading = false,
  isPrimaryButtonDisabled = false,
  isSecondaryButtonDisabled = false,
  footerRightActionComponent,
  hideFooter = false,
}: ModalProps) => {
  //   const theme = getTheme();
  return (
    // <Dialog
    //   //   isOpen={open}
    //   hidden={!open}
    //   onDismiss={onClose}
    //   //   isModeless={true}
    //   modalProps={{
    //     isBlocking: true,
    //     styles: { main: {} },
    //   }}
    //   isClickableOutsideFocusTrap={false}
    //   styles={{
    //     main: modalSx || {
    //       width: "min(80vw, 1200px)",
    //       height: "min(80vh, 720px)",
    //       display: "flex",
    //       flexFlow: "column nowrap",
    //       alignItems: "stretch",
    //       borderRadius: "16px",
    //     },
    //   }}
    // >
    //   <Stack styles={{ root: { width: "100%", height: "100%" }, inner: {} }}>
    //     <Stack
    //       horizontal
    //       horizontalAlign="space-between"
    //       tokens={{
    //         childrenGap: "24px",
    //         padding: "16px 24px",
    //       }}
    //       style={{ width: "100%" }}
    //     >
    //       <Stack.Item align="center">
    //         <Text variant="xLarge">{title}</Text>
    //       </Stack.Item>
    //       <Stack.Item
    //         align="center"
    //         style={{
    //           justifyContent: "center",

    //           alignItems: "center",
    //           display: "flex",
    //         }}
    //       >
    //         {isClosable && (
    //           <IconButton
    //             styles={{
    //               root: {
    //                 borderRadius: "50%",
    //                 color: "black",
    //               },
    //               rootHovered: {
    //                 color: "red",
    //               },
    //             }}
    //             onClick={onClose}
    //           >
    //             <CloseIcon />
    //           </IconButton>
    //         )}
    //       </Stack.Item>
    //     </Stack>
    //     <Stack.Item
    //       grow
    //       style={{
    //         overflowY: "scroll",
    //       }}
    //     >
    //       {children}
    //     </Stack.Item>
    //     <Stack
    //       horizontal
    //       horizontalAlign="end"
    //       verticalAlign="center"
    //       tokens={{
    //         childrenGap: "24px",
    //         padding: "16px 24px",
    //       }}
    //     >
    //       <DefaultButton onClick={() => {}}>Cancel</DefaultButton>
    //       <PrimaryButton style={{}} onClick={() => {}}>
    //         Save
    //       </PrimaryButton>
    //     </Stack>
    //   </Stack>
    // </Dialog>
    <Dialog open={open} onOpenChange={onClose} modalType="alert">
      <DialogSurface
        style={{
          width: "min(80vw, 1200px)",
          maxHeight: "min(80vh, 720px)",
          display: "inline-flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            rowGap: "16px",
          }}
        >
          <div>{title}</div>

          <DialogTrigger disableButtonEnhancement>
            <IconButton
              styles={{
                root: {
                  borderRadius: "50%",
                  color: tokens.colorNeutralForeground1,
                },
                rootHovered: {
                  color: tokens.colorPaletteRedBackground3,
                },
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTrigger>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "16px 0px",
          }}
        >
          {children}
        </DialogContent>
        {!hideFooter && (
          <DialogActions
            style={{
              justifyContent: "end",
              paddingTop: "16px",
            }}
          >
            {footerRightActionComponent && footerRightActionComponent}
            <Button
              appearance="secondary"
              onClick={onSecondaryButtonClick}
              disabled={isLoading || isSecondaryButtonDisabled}
            >
              {secondaryButtonLabel}
            </Button>

            <Button
              appearance="primary"
              disabled={isLoading || isPrimaryButtonDisabled}
              onClick={onPrimaryButtonClick}
            >
              {isLoading ? <Spinner size="extra-tiny" /> : primaryButtonLabel}
            </Button>
          </DialogActions>
        )}
      </DialogSurface>
    </Dialog>
  );
};
