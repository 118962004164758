import disappointed from "assets/lottie/dissapointed.json";
import needToImprove from "assets/lottie/needToImprove.json";
import satisfied from "assets/lottie/satisfied.json";
import good from "assets/lottie/happy.json";
import awesome from "assets/lottie/awesome.json";
import rating1 from "assets/images/1rating.png";
import rating2 from "assets/images/2rating.png";
import rating3 from "assets/images/3rating.png";
import rating4 from "assets/images/4rating.png";
import rating5 from "assets/images/5rating.png";
import { useEffect, useState } from "react";
import {
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import Lottie from "lottie-react";

export const RatingIconButton = ({
  isSelected,
  onClick,
  className,
  rating = 1,
}: {
  className?: string;
  onClick: () => void;
  isSelected: boolean;
  rating: number;
}) => {
  const style = useStyle();
  const [icon, setIcon] = useState<any>({
    inActive: rating1,
    active: disappointed,
  });
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    getIcon();
  }, [isSelected, rating, isHover]);

  const getIcon = () => {
    if (rating === 2) {
      setIcon({
        inActive: rating2,
        active: needToImprove,
      });
    } else if (rating === 3) {
      setIcon({
        inActive: rating3,
        active: satisfied,
      });
    } else if (rating === 4) {
      setIcon({
        inActive: rating4,
        active: good,
      });
    } else if (rating >= 5) {
      setIcon({
        inActive: rating5,
        active: awesome,
      });
    } else {
      setIcon({
        inActive: rating1,
        active: disappointed,
      });
    }
  };
  return (
    <div
      className={mergeClasses(
        style.icon_button,
        isSelected && style.selected_icon,
        className
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onClick()}
    >
      {isSelected || isHover ? (
        <Lottie className={style.icon} animationData={icon?.active} />
      ) : (
        <img className={style.icon} src={icon?.inActive} alt="rating icon" />
      )}
    </div>
  );
};

const useStyle = makeStyles({
  icon_button: {
    width: "40px",
    height: "40px",
    ...shorthands.border("1px", "solid", "#E3E3E3"),
    ...shorthands.borderRadius("4px"),
  },
  selected_icon: {
    ...shorthands.border("1px", "solid", "#5563F5"),
  },
  icon: {
    width: "100%",
    height: "100%",
  },
});
