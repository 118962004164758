import {
  Field,
  RadioGroup,
  Radio,
  Button,
  Text,
  Dropdown,
  Option,
  Persona,
  Select,
  Combobox,
  makeStyles,
  shorthands,
  tokens,
  Subtitle1,
  Caption2,
  Body1,
} from "@fluentui/react-components";
import {
  AttachIcon,
  AttachmentModal,
  Box,
  Input,
  Modal,
  QuillEditor,
  Stack,
} from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { forwardRef, useState } from "react";
import toast from "react-hot-toast";
import { useCreateTicket, useGetTicketTemplates } from "controller";
import {
  useCreateNotification,
  useGetAllMembers,
  useGlobalStore,
  useMainStore,
} from "controller";
import { getFullName } from "Utils";
// "customer_id":"", "customer_user_id": "",
const schema = yup.object({
  ticket_summary: yup.string().required("Summary is required"),
  ticket_description: yup.string().required("Description is required"),
  priority: yup.string().required("priority is required"),
  attachments: yup.array(),
});

interface CreateTicketModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateTicketModal = forwardRef(
  ({ isOpen = false, onClose }: CreateTicketModalProps, ref) => {
    const style = useStyle();
    const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
    const userId = useGlobalStore((state) => state.userId);
    const isPrivilaged = useGlobalStore((state) => state.isPrivilaged);
    const customerId = useGlobalStore((state) => state.customerId);
    const { data: members } = useGetAllMembers({
      customerUserId: userId,
      isPrivilaged: isPrivilaged,
    });
    // console.log("members:", members);
    const attachmentData = useMainStore(
      (state) => state.attachmentModalData.attachments
    );
    const setAttachmentModalData = useMainStore(
      (state) => state.setAttachmentModalData
    );
    const [selectedUser, setSelectedUser] = useState({
      value: userId,
      label: "Myself",
    });
    const resetAttachments = useMainStore(
      (state) => state.resetAttachmentModalData
    );
    const { mutateAsync: createTicket } = useCreateTicket();
    const [isLoading, setIsLoading] = useState(false);
    const {
      control,
      formState: { errors },
      handleSubmit,
      watch,
      setValue,
      setError,
    } = useForm({
      defaultValues: {
        ticket_summary: "",
        ticket_description: "",
        attachments: [],
        priority: "",
      },
      resolver: yupResolver(schema),
    });
    const ticketSummary = watch("ticket_summary");
    const tciketDescription = watch("ticket_description");
    const priority = watch("priority");
    const { data: ticketTemplates } = useGetTicketTemplates({
      filter: [],
      searchQuery: ticketSummary,
      sortColumn: "template_name",
      sortDirection: "asc",
    });
    // console.log("template Data",ticketTemplates);
    const onSubmitHandler = handleSubmit(
      (data) => {
        createTicketHandler({
          ...data,
          attachments: attachmentData.map(
            (item: any) => item?.responseData?.mapping_id
          ),
        });
      },
      (data) => {
        // if (!priority || priority.length <= 0) {
        //   toast.error("Please select priority");
        // }
      }
    );

    const createTicketHandler = async (ticketData: any) => {
      setIsLoading(true);
      try {
        const resp = await createTicket({
          customer_id: customerId,
          customer_user_id: isPrivilaged ? selectedUser.value : userId,
          ...ticketData,
        });
        // console.log(resp);
        onClose();
        resetAttachments();
        toast.success("Created successfully");
      } catch (error: any) {
        toast.error(error?.message || "Unable to create ticket");
      } finally {
        setIsLoading(false);
      }
    };

    const onSelectTemplate = (templateData: any) => {
      setValue("ticket_description", templateData?.template_description);
      setValue("ticket_summary", templateData?.template_summary);
      setValue("priority", templateData?.priority);
      let attchments: Array<any> = (
        Array.isArray(templateData?.template_attachments)
          ? templateData.template_attachments
          : []
      )
        .filter((item: any) => item.mapping_id !== null)
        .map((attachment: any) => ({
          key: attachment.mapping_id,
          name: attachment.original_name,
          size: 0,
          type: attachment.file_type,
          status: "Uploaded",
          progress: 100,
          responseData: {
            mapping_id: attachment.mapping_id,
            original_name: attachment.original_name,
            file_type: attachment.file_type,
            size: 0,
            url: "",
          },
        }));

      setAttachmentModalData([...attchments]);
      attchments = [];
    };

    return isOpen ? (
      <>
        <AttachmentModal
          open={isAttachmentModalOpen}
          onSubmit={(data: Array<any> = []) => {
            setIsAttachmentModalOpen(false);
          }}
          onClose={() => setIsAttachmentModalOpen(false)}
        />
        <Modal
          open={isOpen}
          onClose={() => {
            onClose();
            resetAttachments();
          }}
          isLoading={isLoading}
          title="Create Ticket"
          primaryButtonLabel="Create"
          secondaryButtonLabel="Cancel"
          onPrimaryButtonClick={onSubmitHandler}
          onSecondaryButtonClick={() => {
            onClose();
            resetAttachments();
          }}
          footerRightActionComponent={
            <Box sx={{ position: "relative" }}>
              {attachmentData && attachmentData?.length > 0 && (
                <Box
                  width={"20px"}
                  height={"16px"}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    zIndex: 2,
                    backgroundColor: "red",
                  }}
                >
                  <Text
                    as="span"
                    style={{
                      color: "#FFF",
                      fontSize: "10px",
                    }}
                  >
                    {attachmentData?.length}
                  </Text>
                </Box>
              )}
              <Button
                appearance="transparent"
                icon={<AttachIcon />}
                size="medium"
                aria-label="Edit"
                onClick={() => setIsAttachmentModalOpen(true)}
              />
            </Box>
          }
        >
          <Stack
            width="100%"
            sx={{
              gap: "16px",
            }}
          >
            <Input
              name="ticket_summary"
              placeholder="What is the issue?"
              control={control}
              fullWidth
              maxLength={100}
              error={errors.ticket_summary?.message ? true : false}
              errorMessage={errors.ticket_summary?.message}
            />
            <div className={style.template_list}>
              {Array.isArray(ticketTemplates?.result) &&
                ticketTemplates?.result?.map((item: any) => {
                  return (
                    <div
                      // key={item?.template_id}
                      className={style.template_item}
                      onClick={() => onSelectTemplate(item)}
                    >
                      <Body1>{item?.template_name}</Body1>
                    </div>
                  );
                })}
            </div>
            {isPrivilaged && (
              <Combobox
                appearance="filled-lighter"
                className={style.search_input}
                value={selectedUser.label}
                selectedOptions={[selectedUser.value]}
                onOptionSelect={(ev, data) => {
                  setSelectedUser({
                    label: data.optionText ?? "",
                    value: data.selectedOptions[0],
                  });
                }}
              >
                <Option text="Myself" value={userId}>
                  <Persona
                    name="Myself"
                    avatar={{
                      color: "gold",
                    }}
                  />
                </Option>
                {(Array.isArray(members) ? members : []).map((option) => (
                  <Option
                    key={option?.customer_user_id}
                    text={getFullName({
                      firstName: option?.first_name,
                      lastName: option?.last_name,
                      middleName: "",
                    })}
                    value={option?.customer_user_id}
                  >
                    <div>
                      <Persona
                        name={getFullName({
                          firstName: option?.first_name,
                          lastName: option?.last_name,
                          middleName: "",
                        })}
                        avatar={{
                          image: {
                            src: option?.profile_image,
                          },
                          color: "colorful",
                        }}
                      />
                    </div>
                  </Option>
                ))}
              </Combobox>
            )}
            <Field
              label={"Select priority"}
              validationState={errors?.priority?.message ? "error" : "none"}
              validationMessage={errors?.priority?.message}
            >
              <RadioGroup
                onChange={(_, data) => {
                  setValue("priority", data.value);
                  setError("priority", {
                    message: undefined,
                  });
                }}
                value={priority}
              >
                {(selectedUser.value === userId
                  ? myPriorities
                  : othersPriorities
                ).map((priority: any) => (
                  <Radio
                    key={priority.value}
                    value={priority.value}
                    label={priority.label}
                  />
                ))}
              </RadioGroup>
            </Field>

            <QuillEditor
              placeholder="type here..."
              onChange={(value: string) => {
                setValue("ticket_description", value);
                setError("ticket_description", {
                  message: undefined,
                });
              }}
              value={tciketDescription || ""}
              required={true}
              error={errors?.ticket_description?.message ? true : false}
            />
          </Stack>
        </Modal>
      </>
    ) : null;
  }
);

const myPriorities = [
  { label: "I'm unable to work, fix it immediately.", value: "critical" },
  { label: "I'm able to work but, this is important.", value: "high" },
  {
    label: "I'm able to work but, a quick fix is appreciated.",
    value: "medium",
  },
  { label: "Fix it at your normal pace.", value: "low" },
];
const othersPriorities = [
  { label: "Unable to work, fix it immediately.", value: "critical" },
  { label: "Able to work but, this is important.", value: "high" },
  {
    label: "Able to work but, a quick fix is appreciated.",
    value: "medium",
  },
  { label: "Fix it at your normal pace.", value: "low" },
];

const useStyle = makeStyles({
  search_input: { backgroundColor: "#dcdcdc58 !important" },
  template_list: {
    width: "100%",
    minHeight: "32px",
    display: "flex",
    flexDirection: "row",
    ...shorthands.overflow("scroll", "clip"),
    ...shorthands.gap("8px"),
  },
  template_item: {
    width: "fit-content",
    height: "32px",
    backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    ...shorthands.borderRadius("16px"),
    ...shorthands.padding("8px", "16px"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: tokens.colorNeutralForeground1Static,
    "&:hover": {
      backgroundColor: tokens.colorBrandBackgroundInvertedPressed,
      cursor: "pointer",
      filter: `drop-shadow(0 0 2px ${tokens.colorNeutralShadowKeyDarker})`,
    },
  },
});
