import { makeStyles } from "@fluentui/react-components";
import rating1 from "assets/images/1rating.png";
import rating2 from "assets/images/2rating.png";
import rating3 from "assets/images/3rating.png";
import rating4 from "assets/images/4rating.png";
import rating5 from "assets/images/5rating.png";

export const RatingIcon = ({ rating = 1 }: { rating: number }) => {
  const style = useStyle();
  let img = rating1;

  if (rating === 2) {
    img = rating2;
  } else if (rating === 3) {
    img = rating3;
  } else if (rating === 4) {
    img = rating4;
  } else if (rating >= 5) {
    img = rating5;
  } else {
    img = rating1;
  }

  return (
    <div className={style.icon_box}>
      <img className={style.icon_img} src={img} alt="rating icon" />
    </div>
  );
};

const useStyle = makeStyles({
  icon_img: {
    width: "100%",
    height: "100%",
    aspectRatio: 1,
  },
  icon_box: {
    width: "32px",
    height: "32px",
  },
});
