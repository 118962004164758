import { axiosClient } from "Utils";
import { GetAllChatMessagesInput, SendChatMessageInput } from "shared";

const chatBaseUrl = process.env.REACT_APP_CHAT_BASE_URL;
export const getAllChatMessages = async ({
  serviceId,
  serviceType,
}: GetAllChatMessagesInput) => {
  try {
    const response = await axiosClient.get(
      `${chatBaseUrl}/api/messages/agent/chat/${serviceType}/${serviceId}/messages`
    );
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get all chats");
  }
};

export const sentChatMessage = async ({
  serviceType,
  serviceId,
  ...data
}: SendChatMessageInput) => {
  try {
    const response = await axiosClient.post(
      `${chatBaseUrl}/api/messages/agent/send/message/${serviceType}/${serviceId}`,
      {
        ...data,
        message_platform: "teams_app",
      }
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get all chats");
  }
};
