import { useGlobalStore } from "controller";


export const getCredentialStorage = () => {
  return useGlobalStore.getState();
};


export const cookieGenerator = (aud: string, refreshToken: string) => {
  const d = new Date();
  const newAud = `${aud}`.replace("-", "");

  d.setTime(d.getTime() + 29 * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();

  return (
    `fe_refresh_${newAud}` +
    "=" +
    refreshToken +
    ";expires=" +
    expires +
    `;path=/;domain=${
      process.env.NODE_ENV !== "development" ? ".deskday.ai" : "localhost"
    }`
  );
};
