interface StackProps {
  width?: string;
  height?: string;

  direction?: "row" | "column";
  children?: React.ReactNode;
  sx?: React.CSSProperties | undefined | {};
}
export const Stack: React.FC<StackProps> = ({
  children,
  height,
  sx = {},
  width,
  direction = "column",
}) => (
  <div
    style={{ width, height, display: "flex", flexDirection: direction, ...sx }}
  >
    {children}
  </div>
);
