import { Caption1, Caption1Strong } from "@fluentui/react-components";

export const StatusBadge = ({ id, name }: { id: string; name: string }) => {
  const status = StatusBadgeArray.find((item) => item.id === id) || {
    id: "30f434a9-4e66-4bea-86e3-86bb945035b3",
    value: "Others",
    backgroundColor: "#CBE3E7",
    color: "#33808C",
  };

  return (
    <div
      style={{
        width: "90px",
        height: "28px",
        backgroundColor: status.backgroundColor,
        color: status.color,
        padding: "4px 8px",
        borderRadius: "4px",
      }}
    >
      <Caption1Strong
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1, // number of lines to show
          lineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {name}
      </Caption1Strong>
    </div>
  );
};

const StatusBadgeArray = [
  {
    id: "789c8882-2f6b-495c-ad91-3c9bcbbcc3c7",
    value: "Assigned",
    backgroundColor: "#E4E7FF",
    color: "#001199",
  },
  {
    id: "c302d5e0-176c-4396-9f2c-e6742af81a43",
    value: "Pending",
    backgroundColor: "#FFE6D5",
    color: "#F86A0A",
  },
  {
    id: "e246583c-8714-4c90-a7cc-e3e2d576e780",
    value: "In Progress",
    backgroundColor: "#EBFCFE",
    color: "#3547FD",
  },
  {
    id: "da5be1f9-db9d-4c64-8e43-51b458746773",
    value: "Closed",
    backgroundColor: "#EBFFDC",
    color: "#439900",
  },
  {
    id: "9dd09ea5-e30e-494a-9120-583caff52a3a",
    value: "New",
    backgroundColor: "#F8F1CF",
    color: "#E59400",
  },
  {
    id: "caf3b9ab-08a5-4014-938c-aacc021f043b",
    value: "Merged",
    backgroundColor: "#D7F2FF",
    color: "#006799",
  },
  {
    id: "953b24ef-2167-49f9-88d9-a06cca165753",
    value: "Cancelled",
    backgroundColor: "#FFE8E5",
    color: "#F61E01",
  },
  {
    id: "30f434a9-4e66-4bea-86e3-86bb945035b3",
    value: "Others",
    backgroundColor: "#CBE3E7",
    color: "#33808C",
  },
];
