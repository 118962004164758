import { debugConsole } from "Utils";
import { useEffect, useState } from "react";
// @ts-ignore
import mqtt from "mqtt/dist/mqtt";
import { MqttContext } from "controller";

const mqttHost = process.env.REACT_APP_MQTT_HOST;
const mqttUsername = process.env.REACT_APP_MQTT_USERNAME;
const mqttPassword = process.env.REACT_APP_MQTT_PASSWORD;
export const MqttProvider = ({ children }: { children: React.ReactNode }) => {
  const [mqttMessage, setMqttMessage] = useState<any>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [connectStatus, setConnectStatus] = useState("Connect");

  useEffect(() => {
    if (client) {
      // https://github.com/mqttjs/MQTT.js#event-connect
      client.on("connect", () => {
        setConnectStatus("Connected");
        setIsConnected(true);
        debugConsole("connection successful");
      });

      // https://github.com/mqttjs/MQTT.js#event-error
      client.on("error", (err: any) => {
        setIsConnected(false);
        debugConsole("Connection error: ", err);
        client.end();
      });

      // https://github.com/mqttjs/MQTT.js#event-reconnect
      client.on("reconnect", () => {
        setConnectStatus("Reconnecting");
      });

      // https://github.com/mqttjs/MQTT.js#event-message
      client.on("message", (topic: string, message: any) => {
        const payload = { topic, message: message.toString() };
        setMqttMessage(payload);
        debugConsole(`received message: ${message} from topic: ${topic}`);
      });
    }
  }, [client]);

  const connectMqtt = () => {
    debugConsole("Connecting ...");
    setConnectStatus("Connecting");

    setClient(
      mqtt.connect(`wss://${mqttHost}`, {
        // protocol: "ws",
        // host: "mqtt-prod.deskday.ai",
        clientId: "test_" + Math.random().toString(16).substring(2, 8),
        // ws -> 8083; wss -> 8084
        // port: 8083,
        /**
         * By default, EMQX allows clients to connect without authentication.
         * https://docs.emqx.com/en/enterprise/v4.4/advanced/auth.html#anonymous-login
         */
        host: mqttHost,
        protocol: "wss",
        username: mqttUsername,
        password: mqttPassword,
        clean: true,
        port: 8083,
        path: "/mqtt",
        // reconnectPeriod: 1000, // ms
        connectTimeout: 3000 * 1000, // ms
      })
    );
  };

  const subscribeRoom = (roomId: any) => {
    debugConsole(roomId);
    if (!client) {
      connectMqtt();
    }
    client.subscribe(roomId, (error: any) => {
      if (error) {
        debugConsole("Subscribe to topics error", error);
        return;
      }
      debugConsole(`Subscribe to topics: ${roomId}`);
    });
  };

  const unSubscribeRoom = (roomId: any) => {
    client.unsubscribe(roomId, (error: any) => {
      if (error) {
        debugConsole("UnSubscribe to topics error", error);
        return;
      }
      // debugConsole(`UnSubscribe to topics: ${roomId}`);
    });
  };

  const onMessageArrived = (msg: any) => {
    setMqttMessage(msg);
  };

  return (
    <MqttContext.Provider
      value={{
        connectMqtt,
        mqttMessage,
        subscribeRoom,
        unSubscribeRoom,
        isConnected,
        setIsConnected,
      }}
    >
      <> {children}</>
    </MqttContext.Provider>
  );
};
