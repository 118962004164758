import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addCustomerUser,
  getAllTeamMembers,
  getCurrentCustomerUserUsingAadId,
  getUserProfile,
  getUserProfileWithoutHeader,
  removeCustomerUser,
} from "controller/api";

export const useGetUserProfile = (customerUserId: string) =>
  useQuery({
    enabled: !!customerUserId && customerUserId.length > 0,
    queryKey: ["user", customerUserId],
    queryFn: () => getUserProfile(customerUserId),
  });

export const useAddCustomerUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addCustomerUser,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [variables.aadId || ""] });
    },
  });
};

export const useGetUseProfileUserByMutation = () => {
  return useMutation({
    mutationFn: getUserProfileWithoutHeader,
    onSuccess(data, variables, context) {},
  });
};

export const useRemoveCustomerUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeCustomerUser,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [variables.aadId || ""] });
    },
  });
};

export const useGetAllMembers = ({
  customerUserId = "",
  isPrivilaged = false,
}: {
  customerUserId: string;
  isPrivilaged: boolean;
}) =>
  useQuery({
    enabled: !!customerUserId && customerUserId.length > 0 && isPrivilaged,
    queryKey: ["members", customerUserId],
    queryFn: () => getAllTeamMembers(customerUserId),
  });

export const useGetCurrentCustomerUserUsingAadId = (aadId?: string) =>
  useQuery({
    enabled: !!aadId,
    queryKey: [aadId],
    queryFn: () => getCurrentCustomerUserUsingAadId(aadId || ""),
  });
