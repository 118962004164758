import { Body1 } from "@fluentui/react-components";
import { Box, Modal } from "components";

export const CancelTicketModal = ({
  isOpen,
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: () => void;
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      title="Cancel tickets"
      primaryButtonLabel="Cancel tickets"
      secondaryButtonLabel="Discard"
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={() => onClose()}
    >
      <Body1>Are you sure you want to cancel this ticket</Body1>
    </Modal>
  );
};
