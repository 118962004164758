import {
  Body1,
  Persona,
  Spinner,
  Subtitle2,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { PriorityBadge, RatingViewer, StatusBadge } from "components";
import { useSearchParams } from "react-router-dom";
import "./TicketListViewer.css";
import { GridCard } from "./GridCard";
import { getFullName, isoToDateAndTime } from "Utils";
import { TeamsFxContext } from "controller";
import { useContext } from "react";
// @ts-ignore
import noDataImg from "../../../../assets/images/dataDashboardImg.svg";
import { DISABLED_STATUS } from "shared";
interface TicketListViewerProps {
  isLoading: boolean;
  ticketList: Array<any>;
  view?: "list" | "grid";
  onSelectItem?: () => void;
  onItemClick?: () => void;
  isAllSelected: boolean;
  selectedItems: Array<any>;
  onItemSelected: (selectedItem: any) => void;
  isMemberTickets?: boolean;
  isScrollable: boolean;
}

export const TicketListViewer = ({
  isLoading = false,
  ticketList = [],
  view = "grid",
  isAllSelected,
  onItemSelected,
  selectedItems,
  isMemberTickets = false,
  isScrollable = false,
}: TicketListViewerProps) => {
  const style = useStyle();
  const { themeString } = useContext(TeamsFxContext);
  const [searchParam, setSearchParam] = useSearchParams();
  const onRowClick = async (data: any) => {
    setSearchParam({
      ticketId: data.mapping_id,
      route: "ticket",
    });
    // const resp = await pages.getConfig();
  };

  const cellBuilder: Record<string, (rowData?: any) => React.ReactNode> = {
    ticket_status: (cell: any) => {
      return <StatusBadge id={cell.ticket_status} name={cell.status_name} />;
    },
    ticket_summary: (cell: any) => {
      return <Body1 className={style.title_text}>{cell.ticket_summary}</Body1>;
    },
    priority: (cell: any) => {
      return <PriorityBadge priority={cell.priority} />;
    },
    created_at: (cell: any) => {
      return <Body1>{isoToDateAndTime(cell.created_at)}</Body1>;
    },
    created_by: (cell: any) => {
      return (
        <Persona
          name={getFullName({
            firstName: cell?.customer_user_first_name,
            lastName: cell?.customer_user_last_name,
            middleName: "",
          })}
          avatar={{
            image: {
              src: cell?.customer_user_profile_image,
            },
            color: "colorful",
          }}
        />
      );
    },
    feedback: (cell: any) => {
      return cell?.rating ? <RatingViewer rating={cell?.rating} /> : null;
    },
  };

  const onItemClicked = (item: any) => {
    const index = selectedItems.findIndex(
      (selectedItem) => selectedItem.mapping_id === item.mapping_id
    );
    if (index < 0) {
      onItemSelected([...selectedItems, item]);
      return;
    }

    onItemSelected([
      ...selectedItems.filter(
        (selectedItem) => selectedItem.mapping_id !== item.mapping_id
      ),
    ]);
  };

  const onAllSelectClicked = () => {
    if (isAllSelected) {
      onItemSelected([]);
      return;
    }
    onItemSelected(
      ticketList.filter(
        (item) => !DISABLED_STATUS.includes(item?.ticket_status)
      )
    );
  };

  return (
    <div className="ticket_viewer">
      {isLoading ? (
        <Spinner style={{ width: "100%" }}></Spinner>
      ) : ticketList.length <= 0 ? (
        <div className={style.no_data_body}>
          <img
            className={style.no_data_img_box}
            src={noDataImg}
            alt="no data"
          ></img>

          <Subtitle2>No Data Found!</Subtitle2>
        </div>
      ) : view === "list" ? (
        <div
          className={mergeClasses(
            style.table_box,
            isScrollable ? "" : "stop_scroll"
          )}
        >
          <Table
            style={{
              position: "relative",
              minWidth: "1200px",
            }}
          >
            <TableHeader className={style.table_header}>
              <TableRow className={"table_row"}>
                <TableSelectionCell
                  checked={isAllSelected}
                  onClick={(e) => {
                    onAllSelectClicked();
                  }}
                />
                {(isMemberTickets ? privilagedHeaders : header).map(
                  (item: any) => (
                    <TableHeaderCell key={"header_" + item.key}>
                      <Body1>{item?.title}</Body1>
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHeader>
            <TableBody
              style={{
                overflowY: "scroll",
              }}
            >
              {ticketList.map((item: any) => (
                <>
                  <TableRow
                    className={`table_row content ${
                      themeString === "dark" ? "dark" : ""
                    }`}
                    key={item.mapping_id}
                    onClick={() => onRowClick(item)}
                  >
                    {DISABLED_STATUS.includes(item?.ticket_status) ? (
                      <div></div>
                    ) : (
                      <TableSelectionCell
                        checked={
                          isAllSelected ||
                          selectedItems.find(
                            (selectedItem) =>
                              selectedItem.mapping_id === item.mapping_id
                          )
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          onItemClicked(item);
                          e.stopPropagation();
                        }}
                      />
                    )}
                    {(isMemberTickets ? privilagedHeaders : header).map(
                      (headItem: any) =>
                        cellBuilder && cellBuilder[headItem.key] ? (
                          <TableCell key={"cell_" + headItem.key}>
                            {cellBuilder[headItem.key](item)}
                          </TableCell>
                        ) : (
                          <TableCell key={"cell_" + headItem.key}>
                            <Body1> {item[headItem.key]}</Body1>
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className={`grid_body ${isScrollable ? "" : "stop_scroll"}`}>
          <div className={"grid_container"}>
            {ticketList.map((item: any) => (
              <GridCard
                key={item.mapping_id}
                data={item}
                isSelected={
                  isAllSelected ||
                  selectedItems.find(
                    (selectedItem) =>
                      selectedItem.mapping_id === item.mapping_id
                  )
                    ? true
                    : false
                }
                isSelectable={!DISABLED_STATUS.includes(item?.ticket_status)}
                onItemClicked={onRowClick}
                onItemSelected={() => onItemClicked(item)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const header = [
  {
    title: "Ticket ID",
    key: "u_id",
    sortable: false,
  },
  {
    title: "Summary",
    key: "ticket_summary",
    sortable: false,
  },
  {
    title: "Status",
    key: "ticket_status",
    sortable: false,
  },
  {
    title: "Priority",
    key: "priority",
    sortable: false,
  },
  {
    title: "Created on",
    key: "created_at",
    sortable: false,
  },
  {
    title: "Feedback",
    key: "feedback",
    sortable: false,
  },
];
const privilagedHeaders = [
  {
    title: "Ticket ID",
    key: "u_id",
    sortable: false,
  },
  {
    title: "Summary",
    key: "ticket_summary",
    sortable: false,
  },
  {
    title: "Status",
    key: "ticket_status",
    sortable: false,
  },
  {
    title: "Priority",
    key: "priority",
    sortable: false,
  },
  {
    title: "Created on",
    key: "created_at",
    sortable: false,
  },
  {
    title: "Created by",
    key: "created_by",
    sortable: false,
  },
  {
    title: "Feedback",
    key: "feedback",
    sortable: false,
  },
];

const useStyle = makeStyles({
  title_text: {
    textOverflow: "ellipsis",
    maxLines: "3",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
  },
  no_data_body: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.flex(1),
  },
  no_data_img_box: {
    maxHeight: "200px",
    width: "100%",
    aspectRatio: "1",
    position: "relative",
    ...shorthands.flex(1),
  },
  table_header: {
    position: "sticky",
    top: "0px",
    backgroundColor: tokens.colorNeutralBackground1,
    zIndex: "2",
  },
  table_box: {
    width: "100%",
    ...shorthands.flex(1),
    overflowX: "scroll",
    paddingBottom: "4px",
    "&::-webkit-scrollbar": {
      paddingTop: "4px",
      width: "8px", // Width of vertical scrollbar
      height: "8px", // Height of horizontal scrollbar
      display: "block",
    },
    "&::-webkit-scrollbar-track": {
      paddingTop: "4px",
      marginTop: "4px",
      backgroundColor: "transparent", // Background color of the scrollbar track
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: tokens.colorNeutralBackground1Hover,
      ...shorthands.borderRadius("6px"),
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: tokens.colorNeutralBackground1Pressed,
    },
    "&.stop_scroll": {
      ...shorthands.overflow("hidden"),
    },
  },
});
