import axios from "axios";
import { getCredentialStorage } from "./helpers";
import { Subject } from "rxjs";
import { useGlobalStore } from "controller";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosClient.interceptors.response.use(
  async (res) => {
    if (res.status === 401) {
      useGlobalStore.getState().resetProfileData();
    }
    return res;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      useGlobalStore.getState().resetProfileData();
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(
  (config) => {
    const apiToken = getCredentialStorage().token;
    config.headers["X-API-KEY"] = `${apiToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosUploadCall = (url: string, data: any) => {
  const uploadProgressSubject = new Subject();
  const controller = new AbortController();
  axiosClient
    .post(url, data, {
      // headers: {
      //     'Content-Type': 'multipart/form-data',
      // },
      signal: controller.signal,

      onUploadProgress: function (axiosProgressEvent: any) {
        // console.log(axiosProgressEvent);
        const percentCompleted = Math.round(
          (axiosProgressEvent.loaded * 100) / axiosProgressEvent?.total || 1
        );
        uploadProgressSubject.next({
          response: axiosProgressEvent,
          status: "UPLOADING",
          percentage: percentCompleted,
          controller: controller,
        });
      },
    })
    .then((response) => {
      //  console.log("🚀 ~ file: axios-call.js:19 ~ .then ~ response:", response)
      uploadProgressSubject.next({
        response: response?.data,
        status: "COMPLETE",
        percentage: 100,
        controller: controller,
      });
      uploadProgressSubject.complete();
    })
    .catch((error) => {
      //      console.log("🚀 ~ file: axios-call.js:22 ~ upload file ~ error:", error)
      uploadProgressSubject.error(error);
      uploadProgressSubject.complete();
    });
  return uploadProgressSubject;
};
