import {
  Button,
  Caption1,
  Caption2,
  makeStyles,
  mergeClasses,
  tokens,
  shorthands,
} from "@fluentui/react-components";
import { FileIcon } from "shared";
import { ArrowDownload20Regular } from "@fluentui/react-icons";
import { formatBytes } from "Utils";
import { app } from "@microsoft/teams-js";
export const Attachment = ({
  size,
  title,
  type,
  fileUrl,
  isStretched = false,
}: {
  title: string;
  size?: number;
  type: string;
  fileUrl: string;
  isStretched?: boolean;
}) => {
  const style = useStyle();
  const handleDownload = async () => {
    app.openLink(fileUrl);
  };

  return (
    <div
      className={mergeClasses(
        style.attachment_body,
        isStretched && style.streched
      )}
    >
      <FileIcon type={type} />
      <div className={style.title_box}>
        <Caption1 className={style.text}>{title}</Caption1>
        {size && (
          <Caption2 className={style.text}>{formatBytes(size)}</Caption2>
        )}
      </div>
      <Button
        appearance="transparent"
        icon={<ArrowDownload20Regular />}
        onClick={() => handleDownload()}
      />
    </div>
  );
};
const useStyle = makeStyles({
  attachment_body: {
    width: "100%",
    maxWidth: "400px",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    ...shorthands.borderRadius("4px"),
    ...shorthands.gap("12px", "0px"),
    ...shorthands.padding("8px", "12px"),
    ...shorthands.border("1px", "solid", "#E3E3E3"),
  },
  streched: {
    maxWidth: "100%",
  },
  title_box: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.overflow("hidden"),
    ...shorthands.flex(1),
  },

  text: {
    textOverflow: "ellipsis",
    maxLines: "1",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    ...shorthands.overflow("hidden"),
    color: "#6A6A6A",
  },
});
