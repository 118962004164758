import {
  Button,
  makeStyles,
  tokens,
  shorthands,
  Text,
  Title3,
  Body2,
} from "@fluentui/react-components";
import { LogoutModal } from "components";
import { useGlobalStore } from "controller";
import { useState } from "react";
import expireImg from "assets/images/expire.png";
export const SubscriptionExpireScreen = () => {
  const style = useStyle();
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false);
  const msId = useGlobalStore((state) => state.msId);
  const userId = useGlobalStore((state) => state.userId);
  const token = useGlobalStore((state) => state.token);
  return (
    <div className={style.screen}>
      {isSignoutModalOpen && (
        <LogoutModal
          isOpen={isSignoutModalOpen}
          onClose={() => setIsSignoutModalOpen(false)}
          aadId={msId || ""}
          customerUserId={userId}
          token={token || ""}
        />
      )}
      <img
        className={style.expire_img}
        src={expireImg}
        alt="subscription expire"
      />
      <div className={style.details}>
        <Title3>Access temporarily suspended!</Title3>
        <Body2>Please contact your admin for reactivating your account.</Body2>
      </div>
      <Button appearance="primary" onClick={() => setIsSignoutModalOpen(true)}>
        Log Out
      </Button>
    </div>
  );
};

const useStyle = makeStyles({
  screen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
    width: "100%",
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.gap("32px"),
    ...shorthands.padding("32px", "32px"),
    ...shorthands.overflow("auto"),
  },
  expire_img: {
    width: "468px",
    maxWidth: "80%",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("16px"),
  },
});
