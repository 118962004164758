import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        d="M17 7 7 17M7 7l10 10"
      />
    </svg>
  </SvgIcon>
);
