import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllChatMessages, sentChatMessage } from "controller/api";
import { GetAllChatMessagesInput } from "shared";

export const useGetAllChatMessages = ({
  serviceId,
  serviceType,
}: GetAllChatMessagesInput) => {
  return useQuery({
    queryKey: ["chats", serviceType, serviceId],
    queryFn: () => getAllChatMessages({ serviceId, serviceType }),
  });
};

export const useSendChatMessage = () => {
  return useMutation({
    mutationFn: sentChatMessage,
  });
};
