import {
  AvatarGroup,
  AvatarGroupItem,
  AvatarGroupPopover,
  Body2,
  Caption1,
  Card,
  CardFooter,
  CardHeader,
  Checkbox,
  Subtitle1,
  Subtitle2Stronger,
  makeStyles,
  partitionAvatarGroupItems,
  tokens,
  shorthands,
} from "@fluentui/react-components";
import {
  TicketDiagonal20Regular,
  Calendar20Regular,
} from "@fluentui/react-icons";
import { PriorityBadge, StatusBadge } from "components";
import { isoToDateAndTime } from "Utils";
export const GridCard = ({
  data,
  isSelectable = false,
  onItemClicked,
  isSelected = false,
  onItemSelected = () => {},
}: {
  data: any;
  onItemClicked: (data: any) => void;
  isSelected?: boolean;
  isSelectable: boolean;
  onItemSelected?: (data?: any) => void;
}) => {
  const style=useStyle();
  const partitionedItems = partitionAvatarGroupItems({
    items: Array.isArray(data?.assignees) ? data.assignees : [],
  });
  return (
    <Card className={style.grid_item} onClick={() => onItemClicked(data)}>
      <CardHeader
        className={style.header}
        header={
          <div className={style.title}>
            <Subtitle2Stronger as="span" className="label">
              {data?.ticket_summary}
            </Subtitle2Stronger>
          </div>
        }
        action={
          isSelectable ? (
            <Checkbox
              checked={isSelected}
              onClick={(e) => {
                onItemSelected(data);
                e.stopPropagation();
              }}
            />
          ) : null
        }
      />
      <div className={style.body_item}>
        <TicketDiagonal20Regular />
        <Caption1 className={style.flex_item}>{data.u_id}</Caption1>
      </div>
      <div className={style.body_item}>
        <Calendar20Regular />
        <Caption1 className={style.flex_item}>
          {isoToDateAndTime(data.created_at, {
            format: "DD MMMM YYYY",
          })}
        </Caption1>
      </div>
      <div className={style.body_item}>
        <PriorityBadge priority={data.priority} />
        <StatusBadge id={data.ticket_status} name={data.status_name} />
      </div>

      <CardFooter>
        {Array.isArray(data?.assignees) && data?.assignees.length > 0 ? (
          <AvatarGroup
            layout="spread"
            size={28}
            style={{
              width: "100%",
              padding: "4px 0px",
            }}
          >
            {partitionedItems.inlineItems.map((assignee: any) => (
              <AvatarGroupItem
                name={assignee?.assignee_first_name}
                key={assignee?.assignee_mapping_id}
              />
            ))}
            {partitionedItems.overflowItems && (
              <AvatarGroupPopover>
                {partitionedItems.overflowItems.map((assignee: any) => (
                  <AvatarGroupItem
                    name={assignee?.assignee_first_name}
                    key={assignee?.assignee_mapping_id}
                  />
                ))}
              </AvatarGroupPopover>
            )}
          </AvatarGroup>
        ) : (
          <div
            style={{
              height: "28px",
            }}
          ></div>
        )}
      </CardFooter>
    </Card>
  );
};



const useStyle=makeStyles({
  grid_item :{
    ...shorthands.padding("8px"),
    "& .fui-Card:hover": {
      backgroundColor: "#fafafa",
    }
    ,
    "& .fui-Card": {
      backgroundColor: "#ebebeb",
    }
  }
  ,
  header: {
    width: "100%",
    paddingLeft: "8px",
    paddingTop: "4px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...shorthands.gap("8px"),
  }
  ,
  title: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    
  }
  ,
  body_item: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
    color: tokens.colorNeutralForeground2Link,
    ...shorthands.padding("0px","4px"),
  }
  ,
  flex_item: {
    color: "currentColor",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    ...shorthands.flex(1),
    ...shorthands.overflow("hidden"),
  },

  label: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: 700,
    ...shorthands.overflow("hidden"),
  }
  
})