import { createContext } from "react";

export const MqttContext = createContext<{
  mqttMessage: any;
  connectMqtt: any;
  subscribeRoom: any;
  unSubscribeRoom: any;
  isConnected: boolean;
  setIsConnected: any;
}>({
  mqttMessage: null,
  connectMqtt: () => {},
  subscribeRoom: () => {},
  unSubscribeRoom: () => {},
  isConnected: false,
  setIsConnected: () => {},
});
