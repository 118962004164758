import { AttachmentFileData, HomeFilterInput } from "shared";

import { immer } from "zustand/middleware/immer";
interface CommonDataState {
  attachmentModalData: {
    attachments: Array<any>;
  };
  homefilter: HomeFilterInput;
}

interface CommonDataAction {
  resetAllCommonData: () => void;
  resetAttachmentModalData: () => void;
  setAttachmentModalData: (attachments: Array<AttachmentFileData>) => void;
  resetHomefilter: () => void;
  setHomeFilter: (filter: HomeFilterInput) => void;
  isSubscriptionAvailable: boolean;
  setSubscriptionStatus: (data: boolean) => void;
}

const homeDefaultFilter: HomeFilterInput = {
  priority: [],
  date: "",
  sortDirection: "desc",
  sortBy: "tickets.created_at",
};

export interface CommonDataInterface
  extends CommonDataState,
    CommonDataAction {}

export const CommonDataSlice = immer<CommonDataState & CommonDataAction>(
  (set, get) => ({
    attachmentModalData: {
      attachments: [],
    },
    isSubscriptionAvailable: false,
    homefilter: homeDefaultFilter,
    setAttachmentModalData(attachments) {
      set((state) => {
        state.attachmentModalData = {
          attachments: attachments,
        };
      });
    },
    setHomeFilter(filter) {
      set((state) => {
        state.homefilter = filter;
      });
    },
    resetAllCommonData() {
      set((state) => {
        state.attachmentModalData = {
          attachments: [],
        };
      });
    },
    resetAttachmentModalData() {
      set((state) => {
        state.attachmentModalData = {
          attachments: [],
        };
      });
    },
    setSubscriptionStatus: (data) => {
      set((state) => {
        state.isSubscriptionAvailable = data;
      });
    },
    resetHomefilter() {
      set((state) => {
        state.homefilter = homeDefaultFilter;
      });
    },
  })
);
