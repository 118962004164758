import { axiosClient } from "Utils";
import axios from "axios";
import { AddCustomerUserInput } from "shared";
const botBaseUrl = process.env.REACT_APP_BOT_BASE_URL;
const baseUrl = process.env.REACT_APP_BASE_URL;
export const getUserProfile = async (customerUserId: string) => {
  try {
    const response = await axiosClient.get(
      `/agent/fetch/user/customer/${customerUserId}?filters=[]`
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get profile data");
  }
};

export const getUserProfileWithoutHeader = async ({
  apiToken,
  customerUserId,
}: {
  customerUserId: string;
  apiToken: string;
}) => {
  try {
    const response = await axios.get(
      `${baseUrl}/agent/fetch/user/customer/${customerUserId}?filters=[]`,
      {
        headers: { "X-API-KEY": `${apiToken}` },
      }
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get profile data");
  }
};

export const addCustomerUser = async (
  customerUserData: AddCustomerUserInput
) => {
  try {
    const response = await axiosClient.post(
      `${botBaseUrl}/api/addCustomerUser`,
      customerUserData
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to add customer user");
  }
};

export const removeCustomerUser = async (customerUserData: {
  customerUserId: string;
  aadId: string;
}) => {
  try {
    const response = await axiosClient.post(
      `${botBaseUrl}/api/removeCustomerUser`,
      customerUserData
    );
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to remove customer user");
  }
};

export const getAllTeamMembers = async (customerUserId: string) => {
  try {
    const response = await axiosClient.get(
      `/agent/fetch/team/privilaged/user/${customerUserId}?filters=[]`
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get all team members");
  }
};

export const getCurrentCustomerUserUsingAadId = async (aadId: string) => {
  try {
    const response = await axiosClient.get(
      `${botBaseUrl}/api/getCustomerUser/${aadId}`
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e: any) {
    throw new Error(e?.message || "Unable to get  current user");
  }
};


