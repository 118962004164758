// https://fluentsite.z22.web.core.windows.net/quick-start
import { Button, FluentProvider, Spinner } from "@fluentui/react-components";

import { useTeamsUserCredential } from "@microsoft/teamsfx-react";

import config from "./config";
import { TeamsFxContext } from "controller/contexts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { CloseIcon } from "components";
import { darkTheme, lightTheme } from "Utils";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { MqttProvider, Tab } from "pages";
import { Suspense } from "react";
/**
 * The main app which handles the initialization and routing
 * of the app.
 */

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

// use brand[120] instead of brand[110]

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  return (
    // <ResponsiveContext.Provider value={{ width: 500 }}>
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <MqttProvider>
        <FluentProvider
          theme={{
            ...(themeString === "dark" ? darkTheme : lightTheme),
          }}
          // style={{ background: tokens.colorNeutralBackground3 }}
        >
          <>
            <QueryClientProvider client={queryClient}>
              <Suspense
                fallback={
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner style={{ margin: 100 }} />
                  </div>
                }
              >
                <HashRouter>
                  {loading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner style={{ margin: 100 }} />
                    </div>
                  ) : (
                    <Routes>
                      {/* <Route path="/privacy" element={<Privacy />} />
                  <Route path="/termsofuse" element={<TermsOfUse />} /> */}
                      <Route path="/tab" element={<Tab />} />
                      <Route
                        path="*"
                        element={<Navigate to={"/tab"} />}
                      ></Route>
                    </Routes>
                  )}
                </HashRouter>
              </Suspense>
              {/* <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition="top-right"
              /> */}
            </QueryClientProvider>
            <Toaster
              position="top-right"
              toastOptions={{
                style: {
                  backgroundColor: "#10172E",
                  borderRadius: "100px",
                  minWidth: "300px",
                  maxWidth: "500px",
                  color: "#FFF",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between !important",
                  minHeight: "48px",
                },
                success: {
                  duration: 3000,
                  style: {
                    border: "1px solid #198038",
                  },
                },
                error: {
                  duration: 3000,
                  style: {
                    border: "1px solid #DA1E28",
                  },
                },
                custom: {
                  duration: 3000,

                  style: {
                    border: "1px solid #F1C21B",
                  },
                },
              }}
              // eslint-disable-next-line react/no-children-prop
              children={(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        borderRadius: "24px",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div aria-label="Close Notification">{icon}</div>
                        {message}
                      </div>
                      <Button
                        appearance="transparent"
                        icon={<CloseIcon />}
                        onClick={() => toast.dismiss(t.id)}
                      />
                    </div>
                  )}
                </ToastBar>
              )}
            ></Toaster>
          </>
        </FluentProvider>
      </MqttProvider>
    </TeamsFxContext.Provider>
    // </ResponsiveContext.Provider>
  );
}
